import { makeStyles } from "@mui/styles";
import themes from "themes";

export const useStyles = makeStyles(() => ({
  label: {
    color: "#4F4F4F",
    marginBottom: "8px",
    fontFamily: themes.fontTahoma.family,
    fontSize: "14px !important",
    lineHeight: "16px"
  },
  botao: {
    color: `${themes.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 700,
    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  },
  botaoTransparente: {
    color: `${themes.color.secondary} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center",
    borderColor: `${themes.color.secondary} !important`,
    backgroundColor: "transparent",
    fontSize: "18px",
    lineHeight: "21.6px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent !important"
    },
    "&:disabled": {
      opacity: 1,
      color: "#9F9F9F !important",
      borderColor: "#9F9F9F !important"
    }
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        fontFamily: themes.fontTahoma.family,
        fontSize: "14px"
      }
    }
  },
  naoEncontrado: {
    color: "#4F4F4F",
    fontFamily: themes.fontTahoma.family,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    "& div": {
      fontFamily: themes.fontTahoma.family
    }
  }
}));
