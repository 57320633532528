import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "4",
      asc: "4",
      desc: "0",
      label: "Recentes"
    }
  ];

  return ordenar;
};

export const salvarBoletaGD = async (id, dados) => {
  let metodo = "post";
  let url = "v1/boletagd/";
  if (id > 0) {
    metodo = "put";
    url = `${url}${id}`;
  }
  return api()[metodo](url, dados);
};

export const buscarPorId = async (id) => {
  return api().get(`v1/boletagd/${id}`);
};

export const getByUnidadeId = async (unidadeId) => {
  return api().get(`v1/boletagd/getbyunidadeid/${unidadeId}`);
};

export const listarBoletas = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/boletagd/paginado?${filtroQueryString}`);
};

export const getPrazoPlano = async () => {
  return api().get("v1/boletagdplanoprazo");
};

export const getUnidadeConsumidoraId = async (id) => {
  return api().get(
    `v1/unidadeconsumidora/unidadeconsumidoraid?unidadeConsumidoraId=${id}`
  );
};

export const getByCpfCnpj = async (id) => {
  return api().get(`v1/boletagd/getBycpfcnpj/${id}`);
};

export const reativarBoletaGd = async (body) => {
  return api().patch("v1/boletagd/reativarboletagd", body);
};

export const getTiposBoleta = async () => {
  return api().get("v1/boletagd/tiposboletas");
};
