import { makeStyles } from "@mui/styles";
import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    border: "0 none",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "75% !important",
    [theme.breakpoints.down("lg")]: {
      width: "85% !important"
    },
    [theme.breakpoints.down("md")]: {
      width: "98% !important"
    }
  },
  scroll: {
    padding: "16px 6px 0 16px",
    maxHeight: "100vh",
    overflow: "scroll",
    width: "100%"
  },
  fechar: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    zIndex: 1,
    color: `${themes.color.secondaryText} !important`,
    border: "none",
    backgroundColor: "transparent",
    position: "absolute",
    right: "16px",
    top: "16px"
  },

  header: {
    "& h4": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      margin: 0
    }
  }
}));
