import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import Botao from "componentes/botao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import InputData from "componentes/inputTextoData";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Loader from "componentes/loader";
import SelectArredondado from "componentes/selectArredondado";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { store } from "global/redux";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";
import { buscarDistribuidoras } from "servicos/distribuidorasServico";
import {
  buscarNivelTensao,
  buscarNumeroFases,
  buscarStatus,
  salvarUnidade
} from "servicos/unidadesServico";
import { obterOperacaoControlePorUnidadeId } from "servicos/operacaoControleServico";
import { removerCaracteres, validacaoTrimObject } from "servicos/utils";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { formatarCampo, validarCpfCnpj } from "global/utils/formatCpfCnpj";
import UnidadeHelper from "../../../helper";
import { useStyles } from "../../style";

const GeracaoDistribuidaForm = (props) => {
  const {
    id,
    dadosUnidade,
    rotas,
    menu,
    setNotificacao,
    onSalvar,
    setCarregandoUnidade
  } = props;
  const classes = useStyles();
  const { register, errors, handleSubmit, setError } = useForm({
    reValidateMode: "onSubmit"
  });
  const [listaNumerofases, setListaNumerofases] = useState();
  const [numerofases, setNumerofases] = useState();
  const [
    demandaContratadaForaPonta,
    setDemandaContratadaForaPonta
  ] = useState();
  const [demandaContratadaPonta, setDemandaContratadaPonta] = useState();
  const [valorCnpj, setValorCnpj] = useState();
  const [valorCep, setValorCep] = useState();
  const [dias, setDias] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState(null);
  const [listaAssociacao, setListaAssociacao] = useState([]);
  const [associacaoLuzId, setAssociacaoLuzId] = useState(null);
  const [usinasLuzId, setUsinasLuzId] = useState(null);
  const [listarUsinas, setListarUsinas] = useState([]);
  const [novoCodigoCliente, setNovoCodigoCliente] = useState(null);
  const [dataMigracao, setDataMigracao] = useState(null);
  const [distribuidoraId, setDistribuidoraId] = useState();
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [situacao, setSituacao] = useState(false);
  const [tipoFormulario, setTipoFormulario] = useState(1);
  const [numeroInstalacao, setNumeroInstalacao] = useState();
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [nivelTensaoId, setNivelTensaoId] = useState();
  const [tipoMedidorId, setTipoMedidorId] = useState();
  const [listaStatus, setListaStatus] = useState([]);
  const [statusId, setStatusId] = useState();
  const [
    diaFaturamentoDistribuidora,
    setDiaFaturamentoDistribuidora
  ] = useState();
  const [icms, setIcms] = useState(0);
  const [cupom, setCupom] = useState("");
  const [pessoaFisicaJuridica, setPessoaFisicaJuridica] = useState();
  const [maskCnpjCpf, setMaskCnpjCpf] = useState(null);
  const [maskCnpjAssociacao, setMaskCnpjAssociacao] = useState(null);
  const [limparCnpjAssociacao, setLimparCnpjAssociacao] = useState(false);
  const [cosip, setCosip] = useState(null);
  const [habilitarCampos, setHabilitarCampos] = useState(true);
  const [carregandoUsinas, setCarregandoUsinas] = useState(false);

  const onChangeDataMigracao = (data) => {
    setDataMigracao(data);
  };

  const onChangeNumerofases = (selected) => {
    setNumerofases(selected.target.value);
  };

  const obterListaNumerofases = async () => {
    try {
      const lista = await buscarNumeroFases();
      if (lista?.status === 200 && lista?.data) {
        setListaNumerofases(lista?.data.numerofases);
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    }
  };

  const obterDistribuidora = async () => {
    try {
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    }
  };

  const listaModalidade = [
    { nome: "AZUL", id: 1 },
    { nome: "VERDE", id: 2 }
  ];

  const [modalidadeId, setModalidadeId] = useState();
  const onChangeModalidade = (modalidade) => {
    setTipoFormulario(modalidade.target.value === "2" ? 2 : 1);
    setModalidadeId(modalidade.target.value);
  };

  const onChangeDemandaContratadaForaPonta = (value) => {
    setDemandaContratadaForaPonta(value.target.value);
  };

  const onChangeDemandaContratadaPonta = (value) => {
    setDemandaContratadaPonta(value.target.value);
  };
  const listaUF = [
    { nome: "Acre", UF: "AC" },
    { nome: "Alagoas", UF: "AL" },
    { nome: "Amapá", UF: "AP" },
    { nome: "Amazonas", UF: "AM" },
    { nome: "Bahia", UF: "BA" },
    { nome: "Ceará", UF: "CE" },
    { nome: "Distrito Federal", UF: "DF" },
    { nome: "Espírito Santo", UF: "ES" },
    { nome: "Goiás", UF: "GO" },
    { nome: "Maranhão", UF: "MA" },
    { nome: "Mato Grosso", UF: "MT" },
    { nome: "Mato Grosso do Sul", UF: "MS" },
    { nome: "Minas Gerais", UF: "MG" },
    { nome: "Pará", UF: "PA" },
    { nome: "Paraíba", UF: "PB" },
    { nome: "Paraná", UF: "PR" },
    { nome: "Pernambuco", UF: "PE" },
    { nome: "Piauí", UF: "PI" },
    { nome: "Rio de Janeiro", UF: "RJ" },
    { nome: "Rio Grande do Norte", UF: "RN" },
    { nome: "Rio Grande do Sul", UF: "RS" },
    { nome: "Rondônia", UF: "RO" },
    { nome: "Roraima", UF: "RR" },
    { nome: "Santa Catarina", UF: "SC" },
    { nome: "São Paulo", UF: "SP" },
    { nome: "Sergipe", UF: "SE" },
    { nome: "Tocantins", UF: "TO" }
  ];

  const onChangeDiaFaturamento = (value) => {
    setDiaFaturamentoDistribuidora(value.target.value);
  };

  const obterStatus = async () => {
    try {
      const lista = await buscarStatus();
      if (lista?.status === 200 && lista?.data) {
        setListaStatus(lista?.data);
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    }
  };

  const onChangeNivelTensao = (nivelTensao) => {
    setNivelTensaoId(nivelTensao.target.value);
  };

  const tensaoMaior = useMemo(() => {
    const descricao = listaNivelTensao.filter(function (a) {
      return a.id === nivelTensaoId;
    })[0]?.descricao;
    return descricao?.substring(1, 0) === "A";
  }, [nivelTensaoId, listaNivelTensao]);
  const obterNivelTensao = async () => {
    try {
      const lista = await buscarNivelTensao();
      if (lista?.status === 200 && lista?.data) {
        setListaNivelTensao(lista?.data);
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    }
  };

  const setUnidade = async () => {
    setCarregandoUnidade(true);
    try {
      setDataMigracao(dadosUnidade?.dataPrevistaMigracao);
      setSituacao(String(dadosUnidade?.situacao).toLocaleUpperCase() === "AT");
      setModalidadeId(dadosUnidade?.classeTarifariaId);
      setTipoFormulario(dadosUnidade?.classeTarifariaId === 2 ? 2 : 1);
      setValorCep(dadosUnidade?.cep);
      setValorCnpj(dadosUnidade?.cnpjCpf);
      setMaskCnpjCpf(formatarCampo(dadosUnidade?.cnpjCpf));
      setIcms(dadosUnidade.icms);
      setCupom(dadosUnidade.cupom);
      setCosip(dadosUnidade.cosip !== 0 ? dadosUnidade.cosip : "0");
      setTipoMedidorId(dadosUnidade?.tipoMedidorId);
      setPessoaFisicaJuridica(dadosUnidade?.pessoaFisicaJuridica);
      setCodigoCliente(dadosUnidade?.codigoCliente);
      setNovoCodigoCliente(dadosUnidade?.novoCodigoCliente);
      setNumeroInstalacao(dadosUnidade?.numeroInstalacao);

      await obterDistribuidora();
      setDistribuidoraId(dadosUnidade?.distribuidoraId);

      await obterDias();
      setDiaFaturamentoDistribuidora(dadosUnidade?.diaFaturamentoDistribuidora);

      await obterStatus();
      setStatusId(dadosUnidade?.status);

      await obterNivelTensao();
      setNivelTensaoId(dadosUnidade?.nivelTensaoId);

      await obterListaNumerofases();
      setNumerofases(dadosUnidade?.numerofasesId);

      if (dadosUnidade?.distribuidoraId) {
        await obterAssociacaoPorDistribuidora(dadosUnidade?.distribuidoraId);
      }
      await obterUnidadeUsinaPorUnidadeId(id);

      if (!dadosUnidade?.numerofasesId) {
        setError("numerofasesId", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
      if (!dadosUnidade?.nivelTensaoId) {
        setError("NivelTensao", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
      if (!dadosUnidade?.diaFaturamentoDistribuidora) {
        setError("DiaFaturamentoDistribuidora", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
      if (!dadosUnidade?.numeroInstalacao) {
        setError("numeroInstalacao", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
      if (!dadosUnidade?.icms && dadosUnidade?.icms !== 0) {
        setError("icms", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
      if (!dadosUnidade?.cosip && dadosUnidade?.cosip !== 0) {
        setError("cosip", {
          type: "required",
          message: "Campo é obrigatório!"
        });
      }
    } catch {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    } finally {
      setCarregandoUnidade(false);
    }
  };

  const checarPermissaoEditar = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === RotasDTO.AtualizarStatusMigracao
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item?.functionality?.funcionalidadeColecao.find(
          (f) => f?.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.AtualizarStatusMigracao
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) => InterfaceDTO[item.caminho] === InterfaceDTO.PodeEditar
          );

          return Boolean(filtro);
        }
      }
    }

    return false;
  }, [menu, rotas]);

  const salvarUnidadeUsina = async (unidadeId) => {
    if (!associacaoLuzId) return;

    setCarregandoUnidade(true);

    const usinaAssociacaoId = listarUsinas?.find(
      (item) => Number(item.associacaoId) === Number(associacaoLuzId)
    )?.id;

    const model = {
      unidadeId: Number(unidadeId),
      usinaId: Number(usinasLuzId),
      usinaAssociacaoId
    };

    const { sucesso } = await UnidadeHelper.salvarUnidadeUsina(model);

    if (!sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    }
  };

  const enviarFormulario = async (dados) => {
    let hoje = new Date();
    const dd = String(hoje.getDate()).padStart(2, "0");
    const mm = String(hoje.getMonth() + 1).padStart(2, "0");
    const yyyy = hoje.getFullYear();
    hoje = `${yyyy}-${mm}-${dd}`;

    const cnpjFormatado = dados?.cnpj
      ? dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "")
      : valorCnpj?.replace(/[^a-zA-Z0-9]/g, "");

    delete dados?.icms;
    delete dados?.cosip;
    delete dados?.codigoCliente;
    delete dados?.novoCodigoCliente;
    delete dados?.cupom;

    try {
      setCarregandoUnidade(true);
      const model = {
        ...dadosUnidade,
        Status: statusId,
        DiaFaturamentoDistribuidora: diaFaturamentoDistribuidora,
        Cupom: cupom,
        Cosip: cosip === "0" ? 0 : cosip,
        NivelTensaoId: nivelTensaoId ?? "",
        DistribuidoraId: distribuidoraId,
        PessoaFisicaJuridica: pessoaFisicaJuridica,
        Situacao: id ? (situacao === true ? "AT" : "IN") : "AT",
        DataReferencia: hoje,
        CnpjCpf: cnpjFormatado,
        Icms: icms,
        DemandaContratadaForaPonta: demandaContratadaForaPonta,
        DemandaContratadaPonta: demandaContratadaPonta,
        ClasseTarifariaId: modalidadeId,
        NumerofasesId: numerofases,
        DataPrevistaMigracao: moment(dataMigracao).isValid()
          ? moment(dataMigracao).format("YYYY-MM-DD")
          : "",
        TipoMedidorId: tipoMedidorId,
        CnpjAssociacao: maskCnpjAssociacao,
        CodigoCliente: codigoCliente ?? "",
        NovoCodigoCliente: novoCodigoCliente,
        numeroInstalacao
      };

      const salvou = await salvarUnidade(id, validacaoTrimObject(model));
      if (salvou?.status === 200 || salvou?.status === 204) {
        await salvarUnidadeUsina(id);
        await obterOperacaoControlePorUnidadeId(id);
        setNotificacao({
          aberto: true,
          tipo: "success",
          texto: "01 UC foi vinculada a Associação com sucesso!"
        });

        onSalvar();
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    } finally {
      setCarregandoUnidade(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const [limparCnpj, setLimparCnpj] = useState(false);

  function obterDias() {
    const diasObs = [];
    for (let i = 1; i < 32; i++) {
      diasObs.push({ dia: i });
      if (i === 31) {
        setDias(diasObs);
      }
    }
  }

  function formatarValorSalvar(dado) {
    const formatado = dado
      ? dado
          .replace(" MWh", "")
          ?.replace(" kW", "")
          ?.replace("R$ ", "")
          ?.replace("%", "")
          ?.replaceAll(".", "")
          ?.replace(",", ".")
          ?.trim()
      : dado;

    return formatado;
  }

  const obterAssociacaoPorDistribuidora = async (distribuidoraId) => {
    const {
      data,
      sucesso
    } = await UnidadeHelper.obterAssociacaoPorDistribuidora(distribuidoraId);

    if (!sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
      return;
    }

    const listaAssociacaoAtivas =
      data?.filter((item) => item.situacao === "AT") || [];

    setListaAssociacao(listaAssociacaoAtivas);
  };

  const onChangeAssociacao = async (valor) => {
    setAssociacaoLuzId(valor);
    setListarUsinas([]);
    setUsinasLuzId(null);
    setMaskCnpjAssociacao(null);
    await obterUsinaAssociacaoPorAssociacaoId(valor, distribuidoraId);
  };

  const obterUsinaAssociacaoPorAssociacaoId = async (
    associacaoId,
    distribuidoraId
  ) => {
    const model = {
      associacaoId,
      distribuidoraId
    };
    const {
      data,
      sucesso
    } = await UnidadeHelper.obterUsinaAssociacaoPorAssociacaoId(
      model,
      setCarregandoUsinas
    );

    if (!sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
      return;
    }

    setListarUsinas(data || []);
  };

  const obterUnidadeUsinaPorUnidadeId = async (unidadeId) => {
    const model = {
      unidadeId
    };

    const { data, sucesso } = await UnidadeHelper.obterUnidadeUsinaPorUnidadeId(
      model,
      setCarregandoUsinas
    );

    if (!sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
      return;
    }

    if (!data?.associacaoId) {
      setError("associacaoLuzId", {
        type: "required",
        message: "Campo é obrigatório!"
      });
    }

    if (!data?.usinaId) {
      setError("usinaLuzId", {
        type: "required",
        message: "Campo é obrigatório!"
      });
    }

    setAssociacaoLuzId(data?.associacaoId);
    setUsinasLuzId(data?.usinaId);
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarUnidade]);

  useEffect(() => {
    if (dadosUnidade) {
      setHabilitarCampos(checarPermissaoEditar);
      setUnidade();
    }
  }, [dadosUnidade, checarPermissaoEditar]);

  useEffect(() => {
    if (!associacaoLuzId || !distribuidoraId) return;
    obterUsinaAssociacaoPorAssociacaoId(associacaoLuzId, distribuidoraId);
  }, [associacaoLuzId, distribuidoraId]);

  useEffect(() => {
    if (!associacaoLuzId) {
      setLimparCnpjAssociacao(true);
      return;
    }

    const cnpjSelecionado = listaAssociacao.find(
      (item) => Number(item.id) === Number(associacaoLuzId)
    );
    setMaskCnpjAssociacao(cnpjSelecionado?.cpfCnpj);
  }, [associacaoLuzId, listaAssociacao, limparCnpjAssociacao]);

  return (
    <>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Grid container pt={2} spacing={2} className={classes.container}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <MaterialInputTexto
              type="text"
              id="cnpj"
              name="cnpj"
              label="CPF|CNPJ"
              limparValor={limparCnpj}
              disabled
              redefineLimpar={(estado) => setLimparCnpj(estado)}
              renderIconShowHide={false}
              defaultValue={maskCnpjCpf || ""}
              maxLength={18}
              ref={register({
                required: "Campo obrigatório",
                maxLength: {
                  value: 18,
                  message: "Quantidade máxima de 18 caracteres!"
                },
                minLength: {
                  value: 11,
                  message: "Quantidade mínima de 11 caracteres!"
                },
                validate: (value) => validarCpfCnpj(value)
              })}
              errors={errors}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <MaterialInputTexto
              type="text"
              id="nomeUnidade"
              name="nomeUnidade"
              label="Nome Fantasia"
              disabled
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.nomeUnidade}
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={6}
            xs={12}
            className={classes.containerTelefone}
          >
            <SelectArredondado
              id="Status"
              name="Status"
              valueKey="codigo"
              valueName="descricao"
              dataSource={listaStatus}
              label="Status"
              disabled
              value={statusId}
              placeholder="Status"
              marginBottom="0"
              ref={register(
                { name: "Status" },
                {
                  required: !statusId ? "Campo obrigatório" : false
                }
              )}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <SelectArredondado
              id="Distribuidora"
              name="Distribuidora"
              valueKey="id"
              marginBottom="0"
              valueName="nomeFantasia"
              dataSource={listaDistribuidora}
              label="Distribuidora"
              disabled
              value={distribuidoraId}
              placeholder="Distribuidora"
            />
          </Grid>
        </Grid>
        <Grid container pt={2} spacing={2} className={classes.container}>
          <Grid item xs={12} className="font-weight-bold" pt={1}>
            <h4>Endereço</h4>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="cep"
              name="cep"
              label="CEP"
              mask="#####-###"
              disabled
              renderIconShowHide={false}
              defaultValue={valorCep?.replace("-", "")}
            />
          </Grid>
          <Grid item lg={7} md={7} sm={9} xs={12}>
            <MaterialInputTexto
              type="text"
              id="endereco"
              name="endereco"
              label="Endereço"
              disabled
              renderIconShowHide={false}
              permiteValorBranco
              defaultValue={dadosUnidade?.endereco}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={12}>
            <MaterialInputTexto
              type="text"
              id="numero"
              name="numero"
              label="Número"
              disabled
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.numero}
            />
          </Grid>
        </Grid>
        <Grid container pt={2} spacing={2} className={classes.container}>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="complemento"
              name="complemento"
              label="Complemento"
              disabled
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.complemento ?? ""}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="bairro"
              name="bairro"
              label="Bairro"
              disabled
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.bairro}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <MaterialInputTexto
              type="text"
              id="cidade"
              name="cidade"
              label="Cidade"
              disabled
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.cidade}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <SelectArredondado
              id="uf"
              name="uf"
              valueKey="UF"
              marginBottom="0"
              valueName="UF"
              disabled
              dataSource={listaUF}
              label="UF"
              value={dadosUnidade?.uf}
              placeholder="UF"
            />
          </Grid>
        </Grid>
        <Grid container pt={2} spacing={2} className={classes.container}>
          <Grid item xs={12} className="font-weight-bold" pt={1}>
            <h4>Cadastro complementar</h4>
          </Grid>
          <Grid item p={0} xs={12} lg={2} md={2} sm={5}>
            <InputData
              type="text"
              id="dataMigracao"
              name="dataMigracao"
              label="Data de portabilidade"
              disabled={!habilitarCampos}
              customValue={dataMigracao}
              onChange={(data) => onChangeDataMigracao(data)}
              renderIconShowHide={false}
              ref={register({})}
              errors={errors}
              className={classes}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <SelectArredondado
              id="DiaFaturamentoDistribuidora"
              name="DiaFaturamentoDistribuidora"
              valueKey="dia"
              valueName="dia"
              dataSource={dias}
              label="Dia de faturamento"
              value={diaFaturamentoDistribuidora}
              onChange={(value) => onChangeDiaFaturamento(value)}
              placeholder="Dia de faturamento"
              marginBottom="0"
              allowClear
              ref={register(
                { name: "DiaFaturamentoDistribuidora" },
                {
                  required: !diaFaturamentoDistribuidora
                    ? "Campo obrigatório!"
                    : false
                }
              )}
              disabled={!habilitarCampos}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="numeroInstalacao"
              name="numeroInstalacao"
              label="N° da instalação"
              mask={null}
              renderIconShowHide={false}
              onBlur={(event) => setNumeroInstalacao(event.target.value ?? 0)}
              defaultValue={numeroInstalacao}
              ref={register({
                required: !numeroInstalacao ? "Campo obrigatório" : false,
                maxLength: {
                  value: 50,
                  message: "Quantidade máxima de 50 caracteres!"
                }
              })}
              maxLength={30}
              errors={errors}
              disabled={!habilitarCampos}
              readOnly={!habilitarCampos}
              className={!habilitarCampos ? classes.desabledInput : ""}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="codigoCliente"
              name="codigoCliente"
              label="Código Cliente"
              onBlur={(event) => setCodigoCliente(event.target.value ?? 0)}
              defaultValue={codigoCliente}
              permiteValorBranco
              renderIconShowHide={false}
              ref={register({
                maxLength: {
                  value: 200,
                  message: "Quantidade máxima de 200 caracteres!"
                }
              })}
              errors={errors}
              disabled={!habilitarCampos}
              readOnly={!habilitarCampos}
              className={!habilitarCampos ? classes.desabledInput : ""}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="NovoCodigoCliente"
              name="NovoCodigoCliente"
              label="Novo Código Cliente"
              disabled={!habilitarCampos}
              renderIconShowHide={false}
              permiteValorBranco
              defaultValue={novoCodigoCliente ?? ""}
              onBlur={(e) => {
                setNovoCodigoCliente(e.target.value);
              }}
              ref={register({
                maxLength: {
                  value: 200,
                  message: "Quantidade máxima de 200 caracteres!"
                }
              })}
              errors={errors}
              readOnly={!habilitarCampos}
              className={!habilitarCampos ? classes.desabledInput : ""}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="icms"
              name="icms"
              label="ICMS"
              mask="##%"
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.icms}
              onBlur={(valor) => {
                setIcms(removerCaracteres(valor?.target?.value));
              }}
              ref={register({
                required: !icms ? "Campo obrigatório" : false
              })}
              maxLength={30}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputTexto
              type="text"
              id="cupom"
              name="cupom"
              label="Cupom"
              mask={null}
              renderIconShowHide={false}
              defaultValue={dadosUnidade?.cupom}
              onBlur={(event) => setCupom(event.target.value)}
              maxLength={30}
              errors={errors}
              disabled={!habilitarCampos}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="cosip"
              name="cosip"
              prefixo="R$ "
              label="COSIP"
              defaultValue={dadosUnidade?.cosip ?? cosip}
              renderIconShowHide={false}
              onBlur={(e) => setCosip(formatarValorSalvar(e.target.value))}
              ref={register({
                required: !cosip ? "Campo obrigatório" : false
              })}
              disabled={!habilitarCampos}
              errors={errors}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={5} xs={12}>
            <SelectArredondado
              id="NivelTensao"
              name="NivelTensao"
              valueKey="id"
              marginBottom="0"
              valueName="descricao"
              dataSource={listaNivelTensao}
              label="Nível de tensão"
              disabled={!habilitarCampos}
              value={nivelTensaoId}
              onChange={(nivelTensao) => onChangeNivelTensao(nivelTensao)}
              placeholder="Nível de tensão"
              allowClear
              errors={errors}
              ref={register(
                { name: "NivelTensao" },
                {
                  required: !nivelTensaoId ? "Campo obrigatório" : false
                }
              )}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <SelectArredondado
              id="numerofasesId"
              name="numerofasesId"
              valueKey="id"
              valueName="descricao"
              dataSource={listaNumerofases}
              label="Número de fases"
              disabled={!habilitarCampos}
              value={numerofases}
              onChange={(value) => onChangeNumerofases(value)}
              ref={register(
                { name: "numerofasesId" },
                {
                  required: !numerofases ? "Campo obrigatório" : false
                }
              )}
              placeholder="Número de fases"
              allowClear
              errors={errors}
            />
          </Grid>

          <Grid item lg={2} md={2} sm={5} xs={12}>
            <SelectArredondado
              id="associacaoLuzId"
              name="associacaoLuzId"
              valueKey="id"
              valueName="nomeFantasia"
              dataSource={listaAssociacao}
              label="Associação"
              value={associacaoLuzId ?? ""}
              onChange={(e) => onChangeAssociacao(e.target.value)}
              placeholder="Associação"
              allowClear
              ref={register(
                { name: "associacaoLuzId" },
                {
                  required: !associacaoLuzId ? "Campo obrigatório" : false
                }
              )}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <MaterialInputMascara
              type="text"
              id="CnpjAssociacao"
              name="CnpjAssociacao"
              label="CNPJ Associação"
              mask="##.###.###/####-##"
              limparValor={limparCnpjAssociacao}
              disabled
              redefineLimpar={(estado) => setLimparCnpjAssociacao(estado)}
              renderIconShowHide={false}
              defaultValue={maskCnpjAssociacao || ""}
              ref={register({})}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5} xs={12}>
            <Loader loading={carregandoUsinas}>
              <SelectArredondado
                id="usinaLuzId"
                name="usinaLuzId"
                valueKey="usinaId"
                valueName="nomeUsina"
                dataSource={listarUsinas}
                label="UsinasLuz"
                value={usinasLuzId ?? ""}
                onChange={(e) => setUsinasLuzId(e.target.value)}
                placeholder="UsinasLuz"
                allowClear={associacaoLuzId}
                ref={register(
                  { name: "usinaLuzId" },
                  {
                    required: !usinasLuzId ? "Campo obrigatório" : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid>

          {tensaoMaior && (
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <SelectArredondado
                id="Modalidade"
                name="Modalidade"
                valueKey="id"
                valueName="nome"
                marginBottom="0"
                dataSource={listaModalidade}
                label="Modalidade"
                disabled={!habilitarCampos}
                value={modalidadeId}
                onChange={(modalidade) => onChangeModalidade(modalidade)}
                placeholder="Modalidade"
                allowClear
                errors={errors}
                ref={register(
                  { name: "Modalidade" },
                  {
                    required: !modalidadeId ? "Campo obrigatório" : false
                  }
                )}
              />
            </Grid>
          )}
          {tensaoMaior && (
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputMascara
                type="text"
                id="demandaContratadaForaPonta"
                name="demandaContratadaForaPonta"
                label="Demanda C. fora ponta"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                onChange={(value) => onChangeDemandaContratadaForaPonta(value)}
                ref={register({
                  required: !demandaContratadaForaPonta
                    ? "Campo obrigatório"
                    : false
                })}
                defaultValue={demandaContratadaForaPonta}
                maxLength={32}
                errors={errors}
              />
            </Grid>
          )}
          {tensaoMaior && tipoFormulario === 1 && (
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputMascara
                type="text"
                id="demandaContratadaPonta"
                name="demandaContratadaPonta"
                label="Demanda C. ponta"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                onChange={(value) => onChangeDemandaContratadaPonta(value)}
                ref={register({
                  required:
                    tensaoMaior && tipoFormulario === 1 && "Campo obrigatório"
                })}
                defaultValue={demandaContratadaPonta}
                maxLength={32}
                errors={errors}
              />
            </Grid>
          )}
        </Grid>
        <Grid container pt={3} spacing={3} className={classes.container}>
          <Grid item lg={3} md={4} sm={6} xs={12} pt={1}>
            <Botao
              type="submit"
              label="Salvar"
              disabled={!habilitarCampos}
              className={classes.button}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

GeracaoDistribuidaForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dadosUnidade: PropTypes.oneOfType([PropTypes.any]),
  rotas: PropTypes.oneOfType([PropTypes.any]),
  menu: PropTypes.oneOfType([PropTypes.any]),
  setNotificacao: PropTypes.func,
  onSalvar: PropTypes.func,
  setCarregandoUnidade: PropTypes.func
};

GeracaoDistribuidaForm.defaultProps = {
  id: "",
  dadosUnidade: null,
  rotas: {},
  menu: {},
  setNotificacao: () => {},
  onSalvar: () => {},
  setCarregandoUnidade: () => {}
};

export default GeracaoDistribuidaForm;
