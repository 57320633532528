import { makeStyles } from "@mui/styles";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  snackbar: {
    backgroundColor: "transparent",
    "& .MuiSnackbarContent-root": {
      backgroundColor: (props) =>
        props?.tipo === "success"
          ? "#2ABF6F !important"
          : props?.tipo === "warning"
          ? "#F4A330 !important"
          : "#BF2A2A !important",
      "& .MuiSnackbarContent-message": {
        fontFamily: theme.fontTahoma.family,
        fontSize: "12px"
      }
    }
  }
}));
