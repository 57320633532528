import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px",
      fontFamily: theme.fontTahoma.family
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      fontFamily: theme.fontTahoma.family
    },
    "& h4": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      margin: 0,
      fontFamily: theme.fontTahoma.family
    },
    "& .MuiFormHelperText-root.Mui-error": {
      display: "none"
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px",
      fontFamily: theme.fontTahoma.family
    }
  },
  search: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`,
        fontFamily: theme.fontTahoma.family
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`,
        fontFamily: theme.fontTahoma.family
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  },
  inputTelefone: {
    maxWidth: "80%",
    width: "100%",
    fontFamily: theme.fontTahoma.family,
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerAtivo: {
    margin: "-16px 0 -16px 16px"
  },
  containerSalvar: {
    margin: "0 0 24px"
  },
  inputRadio: {
    marginBottom: "-16px",
    color: "#4F4F4F !important",
    fontFamily: theme.fontTahoma.family
  },
  inputRadioLabel: {
    color: "#4F4F4F !important",
    fontFamily: theme.fontTahoma.family
  },
  desabledInput: {
    pointerEvents: "none",
    opacity: 0.5,
    fontFamily: theme.fontTahoma.family
  }
});
