import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao, SelectArredondado, Loader } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import VincularAssociacaoUCHelper from "../../VincularAssociacaoUC/helper";

import { useStyles } from "./style";

const FormularioAtualizarStatus = (props) => {
  const {
    onCancelar,
    setCarregando,
    setNotificacao,
    obterDados,
    unidadesSelecionadas,
    carregando,
    distribuidoraId,
    textoNaoEncontrado,
    dadosTabela
  } = props;
  const classes = useStyles();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState();
  const [listaAssociacoes, setListaAssociacoes] = useState();
  const [listaUsinas, setListaUsinas] = useState();
  const [associacaoId, setAssociacaoId] = useState();
  const [usinaId, setUsinaId] = useState();

  const obterAssociacoes = async () => {
    setCarregando(true);
    const resultado = await VincularAssociacaoUCHelper.listarAssociacoes(
      distribuidoraId,
      setNotificacao
    );

    setCarregando(false);
    setListaAssociacoes(resultado?.data || []);
  };

  const obterUsinas = async (id) => {
    setCarregando(true);
    const resultado = await VincularAssociacaoUCHelper.listarUsinas(
      id,
      setNotificacao
    );

    setCarregando(false);
    setListaUsinas(resultado?.data || []);
  };

  const onChangeAssociacao = async (valor) => {
    setAssociacaoId(valor);
    setUsinaId();
    if (valor) await obterUsinas(valor);
  };

  const onChangeUsina = (valor) => {
    setUsinaId(valor);
  };

  const validarBotao = () => {
    if (associacaoId && usinaId) {
      setDesabilitarSalvar(false);
      return;
    }

    setDesabilitarSalvar(true);
  };

  const onSalvar = async () => {
    setCarregando(true);
    setDesabilitarSalvar(true);

    let falha = 0;

    const dados = unidadesSelecionadas?.map((unidade) => {
      const dadosSelecionadosTabela = dadosTabela?.data?.paginacao?.items?.find(
        (item) => unidade === item?.id && item?.statusProcessamento === "Falha"
      );

      if (dadosSelecionadosTabela) falha += 1;
      return {
        unidadeId: unidade,
        usinaId: Number(usinaId),
        usinaAssociacaoId: Number(associacaoId)
      };
    });

    const resultadoAtualizar = await VincularAssociacaoUCHelper.vincularAssociacaoUsina(
      {
        processamentoVinculoAssociacaoUsinas: dados
      },
      setNotificacao
    );

    setCarregando(false);
    setDesabilitarSalvar(false);

    if (resultadoAtualizar?.data) {
      if (falha) {
        setNotificacao({
          aberto: true,
          tipo: "error",
          texto: `${falha} ${
            falha === 1 ? "UC esta" : "UCs estão"
          } faltando dados para vincular Associação`
        });
      } else {
        setNotificacao({
          aberto: true,
          tipo: "warning",
          texto:
            unidadesSelecionadas?.length > 1
              ? `${unidadesSelecionadas?.length} UCs estão em processamento!`
              : `${unidadesSelecionadas?.length} UC esta em processamento!`
        });
      }

      onCancelar();
      obterDados({ status: 1, quantidade: unidadesSelecionadas?.length });
    }
  };

  useEffect(() => {
    validarBotao();
  }, [associacaoId, usinaId]);

  useEffect(() => {
    obterAssociacoes();
  }, []);

  return (
    <Loader loading={carregando}>
      <Grid container pt={2}>
        {textoNaoEncontrado ? (
          <>
            <Grid item xs={12} className={classes.naoEncontrado}>
              {textoNaoEncontrado}
            </Grid>
            <Grid item xs={12} pt={3}>
              <BotaoOutLine
                onClick={() => onCancelar()}
                label="Ok"
                className={classes.botaoTransparente}
                disabled={carregando}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} className="font-weight-bold">
              <label htmlFor="associacao" className={classes.label}>
                Associação
              </label>
              <SelectArredondado
                select
                id="associacao"
                name="associacao"
                placeholder="Selecione uma opção"
                valueKey="id"
                valueName="nomeFantasia"
                dataSource={listaAssociacoes}
                value={associacaoId}
                onChange={(e) => {
                  onChangeAssociacao(e.target.value);
                }}
                permiteValorBranco
                className={classes.select}
              />
            </Grid>
            <Grid item xs={12} className="font-weight-bold" pt={2}>
              <label htmlFor="usina" className={classes.label}>
                Usina
              </label>
              <SelectArredondado
                select
                id="usina"
                name="usina"
                placeholder="Selecione uma opção"
                valueKey="id"
                valueName="nomeUsina"
                dataSource={listaUsinas}
                value={usinaId}
                onChange={(e) => {
                  onChangeUsina(e.target.value);
                }}
                permiteValorBranco
                disabled={!associacaoId}
                className={classes.select}
              />
            </Grid>
            <Grid item xs={6} pt={3} pr={1}>
              <BotaoOutLine
                onClick={() => onCancelar()}
                label="Cancelar"
                className={classes.botaoTransparente}
                disabled={carregando}
              />
            </Grid>
            <Grid item xs={6} pt={3}>
              <Botao
                onClick={() => onSalvar()}
                label="Salvar"
                className={classes.botao}
                disabled={desabilitarSalvar || carregando}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Loader>
  );
};

FormularioAtualizarStatus.propTypes = {
  onCancelar: PropTypes.func,
  setCarregando: PropTypes.func,
  setNotificacao: PropTypes.func,
  obterDados: PropTypes.func,
  unidadesSelecionadas: PropTypes.arrayOf([PropTypes.number]),
  carregando: PropTypes.bool,
  distribuidoraId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textoNaoEncontrado: PropTypes.node,
  dadosTabela: PropTypes.oneOfType([PropTypes.any])
};

FormularioAtualizarStatus.defaultProps = {
  onCancelar: () => {},
  setCarregando: () => {},
  setNotificacao: () => {},
  obterDados: () => {},
  unidadesSelecionadas: [],
  carregando: false,
  distribuidoraId: "",
  textoNaoEncontrado: null,
  dadosTabela: {}
};

export default FormularioAtualizarStatus;
