/* eslint-disable quotes */
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Divider, Grid } from "@mui/material";
import Botao from "componentes/botao";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import InputData from "componentes/inputTextoData";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Loader from "componentes/loader";
import ModalConfirmacao from "componentes/modalConfirmacao";
import SelectArredondado from "componentes/selectArredondado";

// Rotas
import { RotasDTO } from "global/rotas/rotasUrlDto";

// Redux
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  desabilitarUnidade,
  salvarUnidades,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "global/redux/modulos/usuario/actions";

// Serviços
import { buscarPorCnpj as buscarClientePorCnpj } from "servicos/clientesServico";
import { obterCepCobertura } from "servicos/correiosServico";
import { buscarDadosCadastraisPorCnpj } from "servicos/dadosCadastraisCnpjServico";
import { buscarDistribuidoras } from "servicos/distribuidorasServico";
import { buscarParcelaDeCarga } from "servicos/parcelaAtivoCCEEServico";
import {
  buscarNivelTensao,
  buscarNumeroFases,
  buscarPorCnpj,
  buscarStatus,
  listarUnidadesConsumidorasCombo,
  salvarUnidade
} from "servicos/unidadesServico";
import { obterOperacaoControlePorUnidadeId } from "servicos/operacaoControleServico";
import {
  fieldEmpty,
  removerCaracteres,
  validacaoTrimObject
} from "servicos/utils";

// DTO
import { InterfaceDTO } from "global/dto/interfacesDto";
import UnidadeHelper from "paginas/Cadastros/UnidadeConsumidora/helper";

import {
  formatarCampo,
  validarCpfCnpj
} from "../../../../../../global/utils/formatCpfCnpj";

// Styles
import { useStyles } from "../../style";

const GeracaoDistribuidaForm = (props) => {
  const {
    id,
    dadosUnidade,
    produtoSelecionado,
    empresaUnidade,
    usuario,
    rotas,
    menu,
    editarUnidade
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarUnidade(false));
    };
    // eslint-disable-next-line
  }, [desabilitarUnidade]);

  const [unidadeExistente, setUnidadeExistente] = useState(false);
  const [idUnidadeExistente, setIdUnidadeExistente] = useState();
  const [unidadeExistenteDados, setUnidadeExistenteDados] = useState();
  const [listaNumerofases, setListaNumerofases] = useState();
  const [numerofases, setNumerofases] = useState();
  const [
    demandaContratadaForaPonta,
    setDemandaContratadaForaPonta
  ] = useState();
  const [demandaContratadaPonta, setDemandaContratadaPonta] = useState();

  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState(null);

  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [valorCnpj, setValorCnpj] = useState();
  const [valorCep, setValorCep] = useState();
  const [limparCep, setLimparCep] = useState(false);
  const [numeroInstalacao, setNumeroInstalacao] = useState();
  const [cnpjFormatado, setCnpjFormatado] = useState();
  const [dias, setDias] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState(null);
  const [listaAssociacao, setListaAssociacao] = useState([]);
  const [carregandoAssociacao, setCarregandoAssociacao] = useState(false);
  const [associacaoLuzId, setAssociacaoLuzId] = useState(null);
  const [usinasLuzId, setUsinasLuzId] = useState(null);
  const [listarUsinas, setListarUsinas] = useState([]);
  const [carregandoUsinas, setCarregandoUsinas] = useState(false);
  const [novoCodigoCliente, setNovoCodigoCliente] = useState(null);

  const onBlurNomeFantasia = (nomeFantasia) => {
    const nomeFantasiaFormatado = nomeFantasia
      .replaceAll("'", "")
      .replaceAll('"', "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  const onConfirmarTelaEdicao = () => {
    setUnidadeExistente(false);
    history.push(
      `${RotasDTO.UnidadesConsumidoras}/cadastro/${idUnidadeExistente}`
    );
  };

  // Situação
  const [situacao, setSituacao] = useState(false);

  // Fases
  const onChangeNumerofases = (selected) => {
    setNumerofases(selected.target.value);
  };

  const obterListaNumerofases = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarNumeroFases();
      if (lista?.status === 200 && lista?.data) {
        setListaNumerofases(lista?.data.numerofases);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  // Data migração
  const [dataMigracao, setDataMigracao] = useState(null);
  const onChangeDataMigracao = (data) => {
    setDataMigracao(data);
  };

  // Distribuidoras
  const [distribuidoraId, setDistribuidoraId] = useState();
  const onChangeDistribuidora = (distribuidora) => {
    setDistribuidoraId(distribuidora.target.value);
  };

  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  // const [dadosUnidade, setDadosUnidade] = useState();

  // Distribuidoras
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const [ufId, setUFId] = useState();
  const onChangeUF = (uf) => {
    setUFId(uf.target.value);
  };

  // CEP
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState();

  const carregarCep = useCallback(async (cep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await obterCepCobertura(cep);

      if (endereco?.status === 200) {
        setUFId(endereco.data.uf);
        setDadosEndereco({
          endereco: endereco.data.logradouro,
          bairro: endereco.data.bairro,
          cidade: endereco.data.cidade
        });
        setNumero(" ");
        setComplemento(endereco.data.complemento);
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setDadosEndereco({
        endereco: "",
        bairro: "",
        cidade: ""
      });
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (cepValor) => {
    if (cepValor.replace(/\s/g, "").length > 7) {
      carregarCep(cepValor);
    }
  };

  const [tipoFormulario, setTipoFormulario] = useState(1);

  const listaModalidade = [
    { nome: "AZUL", id: 1 },
    { nome: "VERDE", id: 2 }
  ];

  const [modalidadeId, setModalidadeId] = useState();
  const onChangeModalidade = (modalidade) => {
    setTipoFormulario(modalidade.target.value === "2" ? 2 : 1);
    setModalidadeId(modalidade.target.value);
  };

  const onChangeDemandaContratadaForaPonta = (value) => {
    setDemandaContratadaForaPonta(value.target.value);
  };

  const onChangeDemandaContratadaPonta = (value) => {
    setDemandaContratadaPonta(value.target.value);
  };
  const listaUF = [
    { nome: "Acre", UF: "AC" },
    { nome: "Alagoas", UF: "AL" },
    { nome: "Amapá", UF: "AP" },
    { nome: "Amazonas", UF: "AM" },
    { nome: "Bahia", UF: "BA" },
    { nome: "Ceará", UF: "CE" },
    { nome: "Distrito Federal", UF: "DF" },
    { nome: "Espírito Santo", UF: "ES" },
    { nome: "Goiás", UF: "GO" },
    { nome: "Maranhão", UF: "MA" },
    { nome: "Mato Grosso", UF: "MT" },
    { nome: "Mato Grosso do Sul", UF: "MS" },
    { nome: "Minas Gerais", UF: "MG" },
    { nome: "Pará", UF: "PA" },
    { nome: "Paraíba", UF: "PB" },
    { nome: "Paraná", UF: "PR" },
    { nome: "Pernambuco", UF: "PE" },
    { nome: "Piauí", UF: "PI" },
    { nome: "Rio de Janeiro", UF: "RJ" },
    { nome: "Rio Grande do Norte", UF: "RN" },
    { nome: "Rio Grande do Sul", UF: "RS" },
    { nome: "Rondônia", UF: "RO" },
    { nome: "Roraima", UF: "RR" },
    { nome: "Santa Catarina", UF: "SC" },
    { nome: "São Paulo", UF: "SP" },
    { nome: "Sergipe", UF: "SE" },
    { nome: "Tocantins", UF: "TO" }
  ];

  const [parcelaDeCarga, setParcelaDeCarga] = useState("");

  const [maskCnpjCpf, setMaskCnpjCpf] = useState(null);
  const verificaCnpj = (valor) => {
    if (!valor) return;
    const mask = formatarCampo(valor);
    setMaskCnpjCpf(mask);
    const formatado = valor?.replace(/[^a-zA-Z0-9]/g, "");
    setCnpjFormatado(formatado);
    verificarCnpjExistente(formatado);
    consultarRF(formatado);
  };

  const [maskCnpjAssociacao, setMaskCnpjAssociacao] = useState(null);
  const [limparCnpjAssociacao, setLimparCnpjAssociacao] = useState(false);

  const consultarRF = useCallback(async (valor) => {
    try {
      setCarregandoCnpj(true);
      const dadosCadastrais = await buscarDadosCadastraisPorCnpj(valor);

      if (dadosCadastrais?.data?.success) {
        const nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
          .replaceAll("'", "")
          .replaceAll('"', "");
        setNomeFantasia(nomeFantasiaFormatado);
        setDemandaContratadaForaPonta("");
        setDemandaContratadaPonta("");
        setStatusId(null);
        zerarCamposParcela();
        zerarCamposEndereco();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "CNPJ inválido!"
          })
        );
        zerarCamposEmpresa();
        zerarCamposParcela();
        zerarCamposEndereco();
      }
      setCarregandoCnpj(false);
    } catch (error) {
      console.info(error);
      setCarregandoCnpj(false);
    }
  }, []);

  const verificarCnpjExistente = useCallback(
    async (valor) => {
      try {
        setCarregandoCnpj(true);
        if (!dadosUnidade) {
          if (valor.length === 14 || valor.length === 11) {
            const unidade = await buscarPorCnpj(valor);
            if (unidade?.data && unidade?.data?.unidadesConsumidoras) {
              setUnidadeExistenteDados(unidade?.data);
              setIdUnidadeExistente(unidade?.data?.unidadesConsumidoras[0].id);
            }

            const dadosCliente = await buscarClientePorCnpj(valor);
            if (dadosCliente?.data) {
              setNomeFantasia(dadosCliente?.data?.nomeFantasia);
              setStatusId(dadosCliente?.data?.status);
              setDadosEndereco({
                endereco: dadosCliente?.data?.endereco,
                bairro: dadosCliente?.data?.bairro,
                cidade: dadosCliente?.data?.cidade
              });
              setValorCep(dadosCliente?.data?.cep);
              setNumero(dadosCliente?.data?.numero);
              setComplemento(dadosCliente?.data?.complemento);
              setUFId(dadosCliente?.data?.uf);
              setClienteId(dadosCliente?.data?.id);
              setPessoaFisicaJuridica(dadosCliente?.data?.pessoaFisicaJuridica);
            } else {
              setClienteInexistente(true);
              consultarRF(valor);
            }

            const verificarParcelaCarga = await buscarParcelaDeCarga(valor);
            if (
              verificarParcelaCarga?.status === 200 &&
              verificarParcelaCarga?.data?.parcelaDeCarga
            ) {
              setParcelaDeCarga(verificarParcelaCarga?.data.parcelaDeCarga);
            } else {
              zerarCamposParcela();
            }
          }
        }
        setCarregandoCnpj(false);
      } catch (error) {
        setClienteInexistente(true);
        console.info(error);
      } finally {
        setCarregandoCnpj(false);
      }
    },
    [dadosUnidade]
  );

  useEffect(() => {
    if (numero === " ") setNumero("");
    if (parcelaDeCarga === " ") setParcelaDeCarga("");
  }, [numero, parcelaDeCarga]);

  const zerarCamposEmpresa = () => {
    setNomeFantasia("");
    setStatusId(null);
  };

  const zerarCamposParcela = () => {
    setParcelaDeCarga(" ");
  };

  const zerarCamposEndereco = () => {
    setLimparCep(true);
    setDadosEndereco(null);
    setNumero("");
    setComplemento("");
    setUFId(null);
  };

  // Status
  const [listaStatus, setListaStatus] = useState([]);
  const [carregandoStatus, setCarregandoStatus] = useState(false);
  const [statusId, setStatusId] = useState();
  const [
    diaFaturamentoDistribuidora,
    setDiaFaturamentoDistribuidora
  ] = useState();

  const onChangeStatus = (status) => {
    setStatusId(status.target.value);
  };

  const onChangeDiaFaturamento = (value) => {
    setDiaFaturamentoDistribuidora(value.target.value);
  };

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarStatus();
      if (lista?.status === 200 && lista?.data) {
        setListaStatus(lista?.data);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  useEffect(() => {
    obterDias();
    obterStatus();
  }, []);

  // Nível Tensão
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [carregandoNivelTensao, setCarregandoNivelTensao] = useState(false);
  const [nivelTensaoId, setNivelTensaoId] = useState();
  // const [tensaoMaior, setTensaoMaior] = useState(true);

  const onChangeNivelTensao = (nivelTensao) => {
    // var descricao = listaNivelTensao.filter(function (a)
    // { return a.id == nivelTensao.target.value })[0]?.descricao
    // descricao?.substring(1, 0) === 'A' ? setTensaoMaior(true) : setTensaoMaior(false)
    setNivelTensaoId(nivelTensao.target.value);
  };

  const tensaoMaior = useMemo(() => {
    // eslint-disable-next-line func-names
    const descricao = listaNivelTensao.filter(function (a) {
      return a.id === nivelTensaoId;
    })[0]?.descricao;
    return descricao?.substring(1, 0) === "A";
  }, [nivelTensaoId, listaNivelTensao]);
  const obterNivelTensao = async () => {
    try {
      setCarregandoNivelTensao(true);
      const lista = await buscarNivelTensao();
      if (lista?.status === 200 && lista?.data) {
        setListaNivelTensao(lista?.data);
      }
      setCarregandoNivelTensao(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoNivelTensao(false);
    }
  };

  // Tipo Medidor
  const [tipoMedidorId, setTipoMedidorId] = useState();

  useEffect(() => {
    obterNivelTensao();
    obterListaNumerofases();
  }, []);

  const setUnidade = () => {
    setNomeFantasia(dadosUnidade?.nomeUnidade);
    setDadosEndereco({
      endereco: dadosUnidade?.endereco,
      bairro: dadosUnidade?.bairro,
      cidade: dadosUnidade?.cidade
    });
    setParcelaDeCarga(dadosUnidade?.parcelaCarga);
    setNumero(dadosUnidade?.numero);
    setComplemento(dadosUnidade?.complemento);
    setStatusId(dadosUnidade?.status);
    setDistribuidoraId(dadosUnidade?.distribuidoraId);
    setNivelTensaoId(dadosUnidade?.nivelTensaoId);
    setDataMigracao(dadosUnidade?.dataPrevistaMigracao);
    setDiaFaturamentoDistribuidora(dadosUnidade?.diaFaturamentoDistribuidora);
    setSituacao(String(dadosUnidade?.situacao).toLocaleUpperCase() === "AT");
    setModalidadeId(dadosUnidade?.classeTarifariaId);
    setTipoFormulario(dadosUnidade?.classeTarifariaId === 2 ? 2 : 1);
    setValorCep(dadosUnidade?.cep);
    setValorCnpj(dadosUnidade?.cnpjCpf);
    setMaskCnpjCpf(formatarCampo(dadosUnidade?.cnpjCpf));
    setUFId(dadosUnidade.uf);
    setProdutoId(parseInt(produtoSelecionado, 10));
    setIcms(dadosUnidade.icms);
    setCupom(dadosUnidade.cupom);
    setCosip(dadosUnidade.cosip !== 0 ? dadosUnidade.cosip : "0");
    setTipoMedidorId(dadosUnidade?.tipoMedidorId);
    setNumerofases(dadosUnidade?.numerofasesId);
    setPessoaFisicaJuridica(dadosUnidade?.pessoaFisicaJuridica);
    setClienteId(dadosUnidade?.clienteId);
    // setMaskCnpjAssociacao(dadosUnidade?.cnpjAssociacao);
    setCodigoCliente(dadosUnidade?.codigoCliente);
    setNovoCodigoCliente(dadosUnidade?.novoCodigoCliente);
  };

  const [produtoId, setProdutoId] = useState(produtoSelecionado);

  const [habilitarCampos, setHabilitarCampos] = useState(true);

  const checarPermissaoEditar = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === RotasDTO[rotas.rotaAtual.caminho]
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.UnidadesConsumidoras
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) => InterfaceDTO[item.caminho] === InterfaceDTO.PodeEditar
          );

          return Boolean(filtro);
        }
      }
    }
  }, [menu, rotas]);

  useEffect(() => {
    if (dadosUnidade) {
      setHabilitarCampos(checarPermissaoEditar);
      setUnidade();
    }
  }, [dadosUnidade, checarPermissaoEditar, produtoSelecionado]);

  const recarregarUnidadesFiltro = useCallback(async () => {
    if (usuario?.clienteSelecionado) {
      const lista = await listarUnidadesConsumidorasCombo(
        usuario?.clienteSelecionado,
        usuario?.token
      );
      if (lista?.status === 200 && lista?.data.unidadeConsumidoraResponseList) {
        store.dispatch(
          salvarUnidades(lista?.data.unidadeConsumidoraResponseList ?? [])
        );
      }
    }
  }, []);

  const [icms, setIcms] = useState(0);
  const [cupom, setCupom] = useState("");

  const [clienteInexistente, setClienteInexistente] = useState(false);
  const [clienteId, setClienteId] = useState();
  const [pessoaFisicaJuridica, setPessoaFisicaJuridica] = useState();

  const salvarUnidadeUsina = async (unidadeId) => {
    if (!associacaoLuzId) return;

    const usinaAssociacaoId = listarUsinas?.find(
      (item) => Number(item.associacaoId) === Number(associacaoLuzId)
    )?.id;

    const model = {
      unidadeId: Number(unidadeId),
      usinaId: Number(usinasLuzId),
      usinaAssociacaoId
    };

    const { mensagem, sucesso } = await UnidadeHelper.salvarUnidadeUsina(
      model,
      setCarregandoUnidade
    );

    if (!sucesso) {
      UnidadeHelper.exibirErro(mensagem);
    }
  };

  const enviarFormulario = async (dados) => {
    if (clienteId === undefined || pessoaFisicaJuridica === undefined) {
      setClienteInexistente(true);
      return;
    }

    let hoje = new Date();
    const dd = String(hoje.getDate()).padStart(2, "0");
    const mm = String(hoje.getMonth() + 1).padStart(2, "0");
    const yyyy = hoje.getFullYear();
    hoje = `${yyyy}-${mm}-${dd}`;

    const cnpjFormatado = dados?.cnpj
      ? dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "")
      : valorCnpj?.replace(/[^a-zA-Z0-9]/g, "");
    // const telefoneFormatado = dados?.telefone?.replace(/[^a-zA-Z0-9]/g, "");

    delete dados?.icms;
    delete dados?.cosip;
    delete dados?.codigoCliente;
    delete dados?.novoCodigoCliente;
    delete dados?.cupom;

    try {
      setCarregandoUnidade(true);
      const model = {
        ...dados,
        UF: ufId,
        Status: statusId,
        DiaFaturamentoDistribuidora: diaFaturamentoDistribuidora,
        Cupom: cupom,
        Cosip: cosip === "0" ? 0 : cosip,
        NivelTensaoId: nivelTensaoId ?? "",
        DistribuidoraId: distribuidoraId,
        ClienteId: clienteId,
        PessoaFisicaJuridica: pessoaFisicaJuridica,
        Situacao: id ? (situacao === true ? "AT" : "IN") : "AT",
        DataReferencia: hoje,
        CnpjCpf: cnpjFormatado,
        Icms: icms,
        DemandaContratadaForaPonta: demandaContratadaForaPonta,
        DemandaContratadaPonta: demandaContratadaPonta,
        ClasseTarifariaId: modalidadeId,
        NumerofasesId: numerofases,
        DataPrevistaMigracao: moment(dataMigracao).isValid()
          ? moment(dataMigracao).format("YYYY-MM-DD")
          : "",
        ProdutoId: produtoId,
        TipoMedidorId: tipoMedidorId,
        CnpjAssociacao: maskCnpjAssociacao,
        CodigoCliente: codigoCliente ?? "",
        NovoCodigoCliente: novoCodigoCliente
      };

      if (id) {
        model.nomeRazaoSocial = dadosUnidade?.nomeRazaoSocial || null;
        model.telefone = dadosUnidade?.telefone || null;
        model.inscricaoEstadual = dadosUnidade?.inscricaoEstadual || null;
        model.inscricaoMunicipal = dadosUnidade?.inscricaoMunicipal || null;
        model.parcelaCarga = dadosUnidade?.parcelaCarga || null;
        model.submercadoId = dadosUnidade?.submercadoId || 0;
        model.tipoRepresentacaoId = dadosUnidade?.tipoRepresentacaoId || 0;
        model.icmsNaNotaFiscal = dadosUnidade?.icmsNaNotaFiscal || null;
        model.cnae = dadosUnidade?.cnae || null;
        model.tipoUnidadeConsumidoraId =
          dadosUnidade?.tipoUnidadeConsumidoraId || null;
        model.consumoHistorico = dadosUnidade?.consumoHistorico || null;
        model.tarifaTUSDImpostos = dadosUnidade?.tarifaTUSDImpostos || null;
        model.tarifaTEImpostos = dadosUnidade?.tarifaTEImpostos || null;
        model.contaSemGD = dadosUnidade?.contaSemGD || null;

        await salvarUnidadeUsina(id);
      }

      const salvou = await salvarUnidade(id ?? 0, validacaoTrimObject(model));
      if (salvou?.status === 200 || salvou?.status === 204) {
        if (!id) {
          await salvarUnidadeUsina(salvou?.data?.id ?? id);
        }
        await obterOperacaoControlePorUnidadeId(salvou?.data?.id ?? id);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Unidade Consumidora salva com sucesso!"
          })
        );
        setTimeout(() => {
          recarregarUnidadesFiltro();
          history.push({
            pathname: RotasDTO.UnidadesConsumidoras,
            state: { dadosUnidade: salvou?.data }
          });
          store.dispatch(selecionarEmpresa(null));
          store.dispatch(selecionarCliente(null));
          store.dispatch(selecionarUnidade(null));
        }, 2000);
      }
    } catch (error) {
      if (clienteId === undefined || pessoaFisicaJuridica === undefined) {
        setClienteInexistente(true);
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    } finally {
      setCarregandoUnidade(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const [limparCnpj, setLimparCnpj] = useState(false);

  const [disabledCpf, setDisabledCpf] = useState(false);

  const verificarPermissaoAlterarCpf = useCallback(async () => {
    setDisabledCpf(true);
    usuario.menu.forEach((permissao) => {
      if (permissao.functionality.nome === "Pode editar CPF") {
        setDisabledCpf(false);
      }
      if (permissao?.functionality?.funcionalidadeColecao) {
        permissao.functionality.funcionalidadeColecao.forEach(
          (funcionalidade) => {
            if (funcionalidade.nome === "Pode editar CPF") {
              setDisabledCpf(false);
            }
            if (funcionalidade?.funcionalidadeColecao) {
              funcionalidade.funcionalidadeColecao.forEach(
                (funcionalidadeNeta) => {
                  if (funcionalidadeNeta.nome === "Pode editar CPF") {
                    setDisabledCpf(false);
                  }
                }
              );
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (id) verificarPermissaoAlterarCpf(id);
  }, [id, verificarPermissaoAlterarCpf]);

  useEffect(() => {
    if (empresaUnidade) {
      store.dispatch(selecionarEmpresa(empresaUnidade?.id));
    }
  }, [empresaUnidade]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (empresaSelecionada && dadosUnidade) {
      store.dispatch(selecionarCliente(dadosUnidade?.clienteId));
    }
  }, [empresaSelecionada, dadosUnidade]);

  useEffect(() => {
    if (
      unidadeExistenteDados &&
      !id &&
      cnpjFormatado === unidadeExistenteDados.unidadesConsumidoras[0].cnpjCpf &&
      numeroInstalacao ===
        unidadeExistenteDados.unidadesConsumidoras[0].numeroInstalacao &&
      Number(distribuidoraId) ===
        unidadeExistenteDados.unidadesConsumidoras[0].distribuidoraId
    ) {
      setUnidadeExistente(true);
    }
  }, [
    cnpjFormatado,
    numeroInstalacao,
    distribuidoraId,
    unidadeExistenteDados,
    id
  ]);

  function obterDias() {
    const diasObs = [];
    for (let i = 1; i < 32; i++) {
      diasObs.push({ dia: i });
      if (i === 31) {
        setDias(diasObs);
      }
    }
  }

  const [cosip, setCosip] = useState(null);

  function formatarValorSalvar(dado) {
    const formatado = dado
      ? dado
          .replace(" MWh", "")
          ?.replace(" kW", "")
          ?.replace("R$ ", "")
          ?.replace("%", "")
          ?.replaceAll(".", "")
          ?.replace(",", ".")
          ?.trim()
      : dado;

    return formatado;
  }

  const obterAssociacaoPorDistribuidora = async (distribuidoraId) => {
    const {
      data,
      sucesso,
      mensagem
    } = await UnidadeHelper.obterAssociacaoPorDistribuidora(
      distribuidoraId,
      setCarregandoAssociacao
    );

    if (!sucesso) {
      UnidadeHelper.exibirErro(mensagem);
      setCarregandoAssociacao(false);
      return;
    }

    const listaAssociacaoAtivas =
      data?.filter((item) => item.situacao === "AT") || [];

    setListaAssociacao(listaAssociacaoAtivas);
  };

  useEffect(() => {
    if (!distribuidoraId) {
      setListaAssociacao([]);
      setListarUsinas([]);
      return;
    }

    obterAssociacaoPorDistribuidora(distribuidoraId);
  }, [distribuidoraId]);

  const obterUsinaAssociacaoPorAssociacaoId = async (associacaoId) => {
    const model = {
      associacaoId,
      distribuidoraId
    };
    const {
      data,
      sucesso,
      mensagem
    } = await UnidadeHelper.obterUsinaAssociacaoPorAssociacaoId(
      model,
      setCarregandoUsinas
    );

    if (!sucesso) {
      UnidadeHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
      return;
    }

    setListarUsinas(data || []);
  };

  useEffect(() => {
    if (!associacaoLuzId) return;
    obterUsinaAssociacaoPorAssociacaoId(associacaoLuzId);
  }, [associacaoLuzId]);

  useEffect(() => {
    if (!associacaoLuzId) {
      setLimparCnpjAssociacao(true);
      return;
    }

    const cnpjSelecionado = listaAssociacao.find(
      (item) => Number(item.id) === Number(associacaoLuzId)
    );
    setMaskCnpjAssociacao(cnpjSelecionado?.cpfCnpj);
  }, [associacaoLuzId, listaAssociacao, limparCnpjAssociacao]);

  useEffect(() => {
    if (associacaoLuzId === "") {
      setListarUsinas([]);
      setUsinasLuzId(null);
      setAssociacaoLuzId(null);
      setMaskCnpjAssociacao(null);
    }
  }, [associacaoLuzId]);

  const obterUnidadeUsinaPorUnidadeId = async (unidadeId) => {
    const model = {
      unidadeId
    };

    const {
      data,
      mensagem,
      sucesso
    } = await UnidadeHelper.obterUnidadeUsinaPorUnidadeId(
      model,
      setCarregandoAssociacao
    );

    if (!sucesso) {
      UnidadeHelper.exibirErro(mensagem);
      return;
    }

    setAssociacaoLuzId(data?.associacaoId);
    setUsinasLuzId(data?.usinaId);
  };

  useEffect(() => {
    if (!id) return;
    obterUnidadeUsinaPorUnidadeId(id);
  }, [id]);

  return (
    <>
      <ModalConfirmacao
        item={unidadeExistente}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="Unidade já cadastrada. Deseja ir para a tela de edição?"
        onCancelar={() => {
          setUnidadeExistente(false);
          zerarCamposEmpresa();
          zerarCamposParcela();
          zerarCamposEndereco();
        }}
      />
      <ModalConfirmacao
        labelBotaoConfirmar="Continuar"
        item={clienteInexistente}
        onclose={() => {
          setLimparCnpj(true);
          setClienteInexistente(false);
          setMaskCnpjCpf(null);
        }}
        onConfirmar={() => {
          setLimparCnpj(true);
          setClienteInexistente(false);
          setMaskCnpjCpf(null);
        }}
        mensagem="CPF/CNPJ não encontrado na base de clientes"
        esconderBotaoCancelar
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Loader loading={carregandoUnidade}>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="cnpj"
                name="cnpj"
                label="CPF|CNPJ"
                limparValor={limparCnpj}
                disabled={disabledCpf || !habilitarCampos}
                redefineLimpar={(estado) => setLimparCnpj(estado)}
                onBlur={(e) => verificaCnpj(e.target.value)}
                renderIconShowHide={false}
                defaultValue={maskCnpjCpf || ""}
                maxLength={18}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 11,
                    message: "Quantidade mínima de 11 caracteres!"
                  },
                  validate: (value) => validarCpfCnpj(value)
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="nomeUnidade"
                  name="nomeUnidade"
                  label="Nome Fantasia"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={nomeFantasia}
                  onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                  onInput={(event) => onBlurNomeFantasia(event.target.value)}
                  ref={register({
                    required: "Campo Nome Fantasia é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      !fieldEmpty(value)
                        ? "Campo Nome Fantasia é obrigatório!"
                        : (!value.includes("'") && !value.includes('"')) ||
                          "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={6}
              xs={12}
              className={classes.containerTelefone}
            >
              <Loader loading={carregandoStatus || carregandoCnpj}>
                <SelectArredondado
                  id="Status"
                  name="Status"
                  valueKey="codigo"
                  valueName="descricao"
                  dataSource={listaStatus}
                  label="Status"
                  disabled={!habilitarCampos}
                  value={statusId}
                  onChange={(status) => onChangeStatus(status)}
                  placeholder="Status"
                  marginBottom="0"
                  allowClear
                  ref={register(
                    { name: "Status" },
                    {
                      required: !statusId
                        ? "Campo Status é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>

            <Grid item lg={3} md={3} sm={5} xs={12}>
              <Loader loading={carregandoDistribuidora}>
                <SelectArredondado
                  id="Distribuidora"
                  name="Distribuidora"
                  valueKey="id"
                  marginBottom="0"
                  valueName="nomeFantasia"
                  dataSource={listaDistribuidora}
                  label="Distribuidora"
                  disabled={!habilitarCampos}
                  value={distribuidoraId}
                  onChange={(distribuidora) =>
                    onChangeDistribuidora(distribuidora)
                  }
                  placeholder="Distribuidora"
                  allowClear
                  ref={register(
                    { name: "Distribuidora" },
                    {
                      required: !distribuidoraId
                        ? "Campo Distribuidora é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item xs={12} className="font-weight-bold">
              Endereço
            </Grid>
            <Grid item lg={3} md={3} sm={5} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputMascara
                  type="text"
                  id="cep"
                  name="cep"
                  label="CEP"
                  mask="#####-###"
                  disabled={!habilitarCampos}
                  limparValor={limparCep}
                  redefineLimpar={(estado) => setLimparCep(estado)}
                  renderIconShowHide={false}
                  defaultValue={valorCep?.replace("-", "")}
                  maxLength={9}
                  onBlur={(event) => onBlurCep(event.target.value)}
                  ref={register({
                    required: "O campo CEP é obrigatório!",
                    minLength: {
                      value: 9,
                      message: "Quantidade minima de 9 caracteres!"
                    },
                    maxLength: {
                      value: 9,
                      message: "Quantidade máxima de 9 caracteres!"
                    },
                    validate: (value) =>
                      value.replace(/\s/g, "").length === 9 ||
                      "Deve ser informado 8 digitos"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={7} md={7} sm={9} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={dadosEndereco?.endereco}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    },
                    validate: (value) =>
                      fieldEmpty(value) || "Campo Endereço é obrigatório!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={4} sm={4} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={numero}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 10,
                      message: "Quantidade máxima de 10 caracteres!"
                    },
                    validate: (value) =>
                      fieldEmpty(value) || "Campo Número é obrigatório!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item lg={3} md={3} sm={5} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={complemento ?? ""}
                  errors={errors}
                  ref={register}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.bairro}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    },
                    validate: (value) =>
                      fieldEmpty(value) || "Campo Bairro é obrigatório!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.cidade}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    },
                    validate: (value) =>
                      fieldEmpty(value) || "Campo Cidade é obrigatório!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={4} xs={12}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  marginBottom="0"
                  valueName="UF"
                  disabled={!habilitarCampos}
                  dataSource={listaUF}
                  label="UF"
                  value={ufId}
                  onChange={(estado) => onChangeUF(estado)}
                  placeholder="UF"
                  allowClear
                  ref={register(
                    { name: "uf" },
                    {
                      required: !ufId ? "Campo UF é obrigatório!" : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              className="font-weight-bold"
            >
              Cadastro complementar
            </Grid>
            <Grid item p={0} xs={12} lg={2} md={2} sm={5}>
              <InputData
                type="text"
                id="dataMigracao"
                name="dataMigracao"
                label="Data de portabilidade"
                disabled={!habilitarCampos}
                customValue={dataMigracao}
                onChange={(data) => onChangeDataMigracao(data)}
                renderIconShowHide={false}
                ref={register({
                  required: id
                    ? "Campo Data de portabilidade é obrigatório!"
                    : false,
                  validate: id
                    ? (value) =>
                        (moment(value, "DD/MM/YYYY").isValid() &&
                          moment(value, "DD/MM/YYYY") >
                            moment("01/01/1900", "DD/MM/YYYY")) ||
                        value === "" ||
                        "Data de portabilidade inválida!"
                    : () => {}
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <SelectArredondado
                id="DiaFaturamentoDistribuidora"
                name="DiaFaturamentoDistribuidora"
                valueKey="dia"
                valueName="dia"
                dataSource={dias}
                label="Dia de faturamento"
                value={diaFaturamentoDistribuidora}
                onChange={(value) => onChangeDiaFaturamento(value)}
                placeholder="Dia de faturamento"
                marginBottom="0"
                allowClear
                ref={register(
                  { name: "DiaFaturamentoDistribuidora" },
                  {
                    required: !diaFaturamentoDistribuidora
                      ? "Campo dia de faturamento é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputTexto
                type="text"
                id="numeroInstalacao"
                name="numeroInstalacao"
                label="N° da instalação"
                disabled={!habilitarCampos}
                mask={null}
                renderIconShowHide={false}
                onBlur={(event) => setNumeroInstalacao(event.target.value ?? 0)}
                defaultValue={dadosUnidade?.numeroInstalacao}
                ref={register({
                  required: id
                    ? "Campo Nº da instalação é obrigatório!"
                    : false,
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                maxLength={30}
                errors={errors}
                readOnly={!editarUnidade}
                className={!editarUnidade ? classes.desabledInput : ""}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputTexto
                type="text"
                id="codigoCliente"
                name="codigoCliente"
                label="Código Cliente"
                defaultValue={dadosUnidade?.codigoCliente ?? ""}
                permiteValorBranco
                renderIconShowHide={false}
                onBlur={(e) => {
                  setCodigoCliente(e.target.value);
                }}
                ref={register({
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  }
                })}
                errors={errors}
                readOnly={!editarUnidade}
                className={!editarUnidade ? classes.desabledInput : ""}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputTexto
                type="text"
                id="novoCodigoCliente"
                name="novoCodigoCliente"
                label="Novo Código Cliente"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                permiteValorBranco
                defaultValue={dadosUnidade?.novoCodigoCliente ?? ""}
                onBlur={(e) => {
                  setNovoCodigoCliente(e.target.value);
                }}
                ref={register({
                  maxLength: {
                    value: 200,
                    message: "Quantidade máxima de 200 caracteres!"
                  }
                })}
                errors={errors}
                readOnly={!editarUnidade}
                className={!editarUnidade ? classes.desabledInput : ""}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputMascara
                type="text"
                id="icms"
                name="icms"
                label="ICMS"
                mask="##%"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.icms}
                onBlur={(valor) => {
                  setIcms(removerCaracteres(valor?.target?.value));
                }}
                ref={register({
                  required: id ? "Campo ICMS é obrigatório!" : false
                })}
                maxLength={30}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputTexto
                type="text"
                id="cupom"
                name="cupom"
                label="Cupom"
                disabled={!habilitarCampos}
                mask={null}
                renderIconShowHide={false}
                onBlur={(event) => setCupom(event.target.value)}
                defaultValue={dadosUnidade?.cupom}
                maxLength={30}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputMascara
                type="text"
                id="cosip"
                name="cosip"
                prefixo="R$ "
                label="COSIP"
                defaultValue={dadosUnidade?.cosip ?? cosip}
                renderIconShowHide={false}
                onBlur={(e) => setCosip(formatarValorSalvar(e.target.value))}
                ref={register({
                  required: id ? "Campo COSIP é obrigatório!" : false
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={5} xs={12}>
              <Loader loading={carregandoNivelTensao}>
                <SelectArredondado
                  id="NivelTensao"
                  name="NivelTensao"
                  valueKey="id"
                  marginBottom="0"
                  valueName="descricao"
                  dataSource={listaNivelTensao}
                  label="Nível de tensão"
                  disabled={!habilitarCampos}
                  value={nivelTensaoId}
                  onChange={(nivelTensao) => onChangeNivelTensao(nivelTensao)}
                  placeholder="Nível de tensão"
                  allowClear
                  errors={errors}
                  ref={register(
                    { name: "NivelTensao" },
                    {
                      required:
                        !nivelTensaoId && id
                          ? "Campo Nível de tensão é obrigatório!"
                          : false
                    }
                  )}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <SelectArredondado
                id="numerofasesId"
                name="numerofasesId"
                valueKey="id"
                valueName="descricao"
                dataSource={listaNumerofases}
                label="Número de fases"
                disabled={!habilitarCampos}
                value={numerofases}
                onChange={(value) => onChangeNumerofases(value)}
                ref={register(
                  { name: "numerofasesId" },
                  {
                    required:
                      !numerofases && id
                        ? "Campo Nº de fases é obrigatório!"
                        : false
                  }
                )}
                placeholder="Número de fases"
                allowClear
                errors={errors}
              />
            </Grid>

            <Grid item lg={2} md={2} sm={5} xs={12}>
              <Loader loading={carregandoAssociacao}>
                <SelectArredondado
                  id="associacaoLuzId"
                  name="associacaoLuzId"
                  valueKey="id"
                  valueName="nomeFantasia"
                  dataSource={listaAssociacao}
                  label="Associação"
                  value={associacaoLuzId ?? ""}
                  onChange={(e) => setAssociacaoLuzId(e.target.value)}
                  placeholder="Associação"
                  allowClear
                  ref={register(
                    { name: "associacaoLuzId" },
                    {
                      required:
                        !associacaoLuzId && id
                          ? "Campo Associação é obrigatório!"
                          : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <MaterialInputMascara
                type="text"
                id="CnpjAssociacao"
                name="CnpjAssociacao"
                label="CNPJ Associação"
                mask="##.###.###/####-##"
                limparValor={limparCnpjAssociacao}
                disabled
                redefineLimpar={(estado) => setLimparCnpjAssociacao(estado)}
                renderIconShowHide={false}
                defaultValue={maskCnpjAssociacao || ""}
                ref={register({
                  required: id ? "Campo CNPJ Associação é obrigatório!" : false
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={5} xs={12}>
              <Loader loading={carregandoUsinas}>
                <SelectArredondado
                  id="usinaLuzId"
                  name="usinaLuzId"
                  valueKey="usinaId"
                  valueName="nomeUsina"
                  dataSource={listarUsinas}
                  label="UsinasLuz"
                  value={usinasLuzId ?? ""}
                  onChange={(e) => setUsinasLuzId(e.target.value)}
                  placeholder="UsinasLuz"
                  allowClear={associacaoLuzId}
                  ref={register(
                    { name: "usinaLuzId" },
                    {
                      required:
                        !usinasLuzId && id
                          ? "Campo Usinas é obrigatório!"
                          : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>

            {tensaoMaior && (
              <Grid item lg={2} md={2} sm={5} xs={12}>
                <SelectArredondado
                  id="Modalidade"
                  name="Modalidade"
                  valueKey="id"
                  valueName="nome"
                  marginBottom="0"
                  dataSource={listaModalidade}
                  label="Modalidade"
                  disabled={!habilitarCampos}
                  value={modalidadeId}
                  onChange={(modalidade) => onChangeModalidade(modalidade)}
                  placeholder="Modalidade"
                  allowClear
                  errors={errors}
                  ref={register(
                    { name: "Modalidade" },
                    {
                      required:
                        !modalidadeId && id
                          ? "Campo Modalidade é obrigatório!"
                          : false
                    }
                  )}
                />
              </Grid>
            )}
            {tensaoMaior && (
              <Grid item lg={2} md={2} sm={5} xs={12}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratadaForaPonta"
                  name="demandaContratadaForaPonta"
                  label="Demanda C. fora ponta"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  onChange={(value) =>
                    onChangeDemandaContratadaForaPonta(value)
                  }
                  ref={register({
                    required: "Campo Demanda C. fora ponta é obrigatório!"
                  })}
                  defaultValue={dadosUnidade?.demandaContratadaForaPonta}
                  maxLength={32}
                  errors={errors}
                />
              </Grid>
            )}
            {tensaoMaior && tipoFormulario === 1 && (
              <Grid item lg={2} md={2} sm={5} xs={12}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratadaPonta"
                  name="demandaContratadaPonta"
                  label="Demanda C. ponta"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  onChange={(value) => onChangeDemandaContratadaPonta(value)}
                  ref={register({
                    required:
                      tensaoMaior &&
                      tipoFormulario === 1 &&
                      "Campo Demanda C. ponta é obrigatório!"
                  })}
                  defaultValue={dadosUnidade?.demandaContratadaPonta}
                  maxLength={32}
                  errors={errors}
                />
              </Grid>
            )}
          </Grid>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Botao
                type="submit"
                label="Salvar"
                disabled={!habilitarCampos}
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Loader>
      </form>
    </>
  );
};

GeracaoDistribuidaForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dadosUnidade: PropTypes.oneOfType([PropTypes.any]),
  produtoSelecionado: PropTypes.oneOfType([PropTypes.any]),
  empresaUnidade: PropTypes.oneOfType([PropTypes.any]),
  usuario: PropTypes.oneOfType([PropTypes.any]),
  rotas: PropTypes.oneOfType([PropTypes.any]),
  menu: PropTypes.oneOfType([PropTypes.any]),
  editarUnidade: PropTypes.bool
};

GeracaoDistribuidaForm.defaultProps = {
  id: "",
  dadosUnidade: {},
  produtoSelecionado: {},
  empresaUnidade: {},
  usuario: {},
  rotas: {},
  menu: {},
  editarUnidade: false
};

export default GeracaoDistribuidaForm;
