import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import {
  obterAssociacaoPorDistribuidora,
  obterAssociacao
} from "servicos/associacaoLuzServico";
import RadioDto from "componentes/inputRadioGroup/radioDto";
import { obterUsinaAssociacaoPorAssociacaoId } from "servicos/usinasLuzServico";
import {
  obterUnidadeUsinaPorUnidadeId,
  salvarUnidadeUsina
} from "servicos/unidadesServico";

export default class UnidadeHelper {
  static obterProdutos() {
    return [
      new RadioDto("Mercado Livre", "1"),
      new RadioDto("Geração Distribuída", "4"),
      new RadioDto("Geração Distribuidora - Energia Consumida", "5")
    ];
  }

  static async obterAssociacao(setCarregandoAssociacao) {
    try {
      setCarregandoAssociacao(true);
      const resultado = await obterAssociacao();

      return RetornoEndpointDto.Sucesso(
        "Associação obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Associação não encontrados!");
    } finally {
      setCarregandoAssociacao(false);
    }
  }

  static async obterUsinaAssociacaoPorAssociacaoId(
    params,
    setCarregandoUsinas
  ) {
    setCarregandoUsinas(true);
    try {
      const resultado = await obterUsinaAssociacaoPorAssociacaoId(params);

      return RetornoEndpointDto.Sucesso(
        "Usinas obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Usinas não encontrados!");
    } finally {
      setCarregandoUsinas(false);
    }
  }

  static async salvarUnidadeUsina(body) {
    try {
      const resultado = await salvarUnidadeUsina(body);

      return RetornoEndpointDto.Sucesso(
        "Usinas salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao salvar usinas!");
    }
  }

  static async obterUnidadeUsinaPorUnidadeId(params, setCarregandoUsinas) {
    try {
      const resultado = await obterUnidadeUsinaPorUnidadeId(params);

      return RetornoEndpointDto.Sucesso(
        "Unidades obtido com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao buscar unidades!");
    } finally {
      setCarregandoUsinas(false);
    }
  }

  static async obterAssociacaoPorDistribuidora(distribuidoraId) {
    try {
      const resultado = await obterAssociacaoPorDistribuidora(distribuidoraId);

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso(
          "Associação por distribuidora não encontrados!",
          []
        );

      return RetornoEndpointDto.Sucesso(
        "Associação por distribuidora não encontrados!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Erro ao obter associação por distribuidora!"
      );
    }
  }
}
