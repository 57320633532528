import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import {
  obterListagemCuponsAssociados,
  listarClientes,
  obterClienteCupomIndicadorPorCliente,
  criarIndicacao
} from "servicos/memberGetMemberServico";
import { listarUnidadesConsumidorasCombo } from "servicos/unidadesServico";

export default class ListagemHelper {
  static async listarPaginado(filtro, setNotificacao) {
    try {
      const resultado = await obterListagemCuponsAssociados(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de Operação Controle obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Erro no sistema, tente novamente mais tarde!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Erro no sistema, tente novamente mais tarde!"
      );
    }
  }

  static async obterClienteCupom(clienteId, setNotificacao) {
    try {
      const resultado = await obterClienteCupomIndicadorPorCliente(clienteId);

      return RetornoEndpointDto.Sucesso(
        "Listagem de clientes obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Erro no sistema, tente novamente mais tarde!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Erro no sistema, tente novamente mais tarde!"
      );
    }
  }

  static async obterClientes(campo, texto, setNotificacao) {
    try {
      const resultado = await listarClientes(campo, texto);

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso("204", []);

      return RetornoEndpointDto.Sucesso(
        "Listagem de clientes obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Erro no sistema, tente novamente mais tarde!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Erro no sistema, tente novamente mais tarde!"
      );
    }
  }

  static async listarUnidadesPorClienteId(clienteId, setNotificacao) {
    try {
      const resultado = await listarUnidadesConsumidorasCombo(clienteId);

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso("204", null);

      return RetornoEndpointDto.Sucesso(
        "Listagem de unidades obtida com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Erro no sistema, tente novamente mais tarde!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Erro no sistema, tente novamente mais tarde!"
      );
    }
  }

  static async postarCupom(dados, setNotificacao) {
    try {
      const resultado = await criarIndicacao(dados);

      return RetornoEndpointDto.Sucesso(
        "Indicação salva com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Erro no sistema, tente novamente mais tarde!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Erro no sistema, tente novamente mais tarde!"
      );
    }
  }
}
