import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStyles } from "./style";

const ModalCancelamento = (props) => {
  const { item, onCancelar, conteudo, titulo } = props;

  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.container}
      >
        <div className={classes.scroll}>
          <Grid container item xs={12}>
            {titulo && (
              <Grid item xs={10} className={classes.header}>
                <h4>{titulo}</h4>
              </Grid>
            )}
            <Grid item xs={2} className={classes.fechar}>
              <button
                type="button"
                onClick={onClose}
                className={`${classes.button} `}
                label="fechar"
              >
                <CloseIcon />
              </button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {conteudo}
          </Grid>
        </div>
      </Grid>
    </Modal>
  );
};

ModalCancelamento.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  conteudo: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  titulo: PropTypes.string
};

ModalCancelamento.defaultProps = {
  onCancelar: () => {},
  conteudo: {},
  titulo: ""
};

export default ModalCancelamento;
