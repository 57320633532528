import React, { useRef, useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";

import { extendMoment } from "moment-range";

// Redux
import { useSelector } from "react-redux";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { desabilitarUnidade } from "../../../../global/redux/modulos/usuario/actions";

// Styles
import theme from "../../../../themes";
import { useStyles } from "./style";

// Componentes
import Botao from "../../../../componentes/botao";
import TabelaPaginada from "../../../../componentes/tabelaPaginada";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import BoletasHelper from "./helper";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

// DTO
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ListagemBoletasDto from "./dto/listagemBoletasDto";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Serviços
import { listarOrganizarPor } from "../../../../servicos/boletasGDServico";
import { listarStatus } from "../../../../servicos/statusServico";
import { listarPagamento } from "../../../../servicos/pagamentoServico";

// import IconExcel from "../../../../assets/icones/medidores/excel.svg";
import MaterialInputBusca from "../../../../componentes/inputBusca";

const ListagemBoletaGD = () => {
  const rotas = useSelector((state) => state.rotas);
  const moment = extendMoment(window.moment);
  const history = useHistory();
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroSituacaoSelecionado] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const montouComponente = useRef(false);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState("");
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const [filtroPagamentoSelecionado, setFiltroPagamentoSelecionado] = useState(
    ""
  );

  const [statusLista, setStatusLista] = useState([]);
  const [pagamentoLista, setPagamentoLista] = useState([]);

  const classes = useStyles();
  const refTabela = useRef(null);

  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: history?.location?.state?.dadosBoletaGd?.id?.toString() || ""
  });

  const obterStatus = async () => {
    try {
      const lista = await listarStatus();
      if (lista?.status === 200 && lista?.data?.status) {
        setStatusLista(lista?.data.status ?? []);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const filtroStatusPor = () => {
    return statusLista.map((status) => ({
      key: status.id,
      label: status.descricao
    }));
  };

  const obterPagamento = async () => {
    try {
      const lista = await listarPagamento();
      if (lista?.status === 200 && lista?.data) {
        setPagamentoLista(lista?.data ?? []);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const filtroPagamentoPor = () => {
    return pagamentoLista.map((pagamento) => ({
      key: pagamento.id,
      label: pagamento.descricao
    }));
  };

  useEffect(() => {
    setCarregamentoInicial(true);
    obterStatus();
    obterPagamento();
  }, []);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.BoletasGD}/cadastro/${id}`);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("id", "Boleta", true, "8%", "8%"),
    new Coluna("clienteNome", "Cliente", true, "16%", "16%"),
    new Coluna("unidadeConsumidora", "Unidade consumidora", true, "15%", "15%"),
    new Coluna("desconto", "Desconto", true, "10%", "10%"),
    new Coluna("dataCriacaoBoleta", "Data Criação", true, "13%", "13%"),
    new Coluna(
      "dataInicioFornecimento",
      "Data fornecimento",
      true,
      "13%",
      "13%"
    ),
    new Coluna("formaPagamento", "Forma de pagamento", true, "10%", "10%"),
    new Coluna("status", "Status", true, "7%", "7%"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "6%",
      "6%",
      "pl-2"
    )
  ];

  function formatarValorTabela(valor) {
    return valor
      ? valor.toLocaleString("pt-BR", {
          style: "decimal",
          currency: "BRL",
          minimumFractionDigits: 2
        })
      : valor;
  }

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto(
      parametros?.tamanhoPagina,
      parametros?.pagina,
      parametros?.pesquisar || textoPesquisa,
      parametros?.filtroAdicional?.ordenacao ?? "",
      parametros?.filtroAdicional?.situacao ?? "",
      parametros?.filtroAdicional?.pagamento ?? "",
      parametros?.filtroAdicional?.status ?? "",
      parametros?.filtroAdicional?.unidadeSelecionada ?? "",
      parametros?.filtroAdicional?.clienteSelecionado ?? "",
      parametros?.filtroAdicional?.empresaSelecionada ?? ""
    );

    const resultado = await BoletasHelper.listarBoletas(filtro, history, rotas);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );

      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data?.boletas.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.boletas.map((res) => {
      const dataInicio = moment(res.dataCriacaoBoleta).format("DD-MM-yyyy");
      const fornecimento = moment(res.dataInicioFornecimento).format(
        "DD-MM-yyyy"
      );
      const desconto = `${formatarValorTabela(res.desconto)}`;
      return new ListagemBoletasDto(
        res.id,
        res.id,
        res.clienteNome,
        res.unidadeConsumidora,
        desconto,
        dataInicio,
        fornecimento,
        res.formaPagamento,
        res.status
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterGrupos({
      tamanhoPagina: parametros?.totalPagina,
      pagina: parametros?.pagina,
      pesquisar: parametros?.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.ordenacao,
        parametros?.filtrosAdicionais?.situacao,
        parametros?.filtrosAdicionais?.pagamento,
        parametros?.filtrosAdicionais?.status,
        parametros?.filtrosAdicionais?.empresaSelecionada,
        parametros?.filtrosAdicionais?.clienteSelecionado,
        parametros?.filtrosAdicionais?.unidadeSelecionada
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroPagamentoSelecionado,
        filtroStatusSelecionado,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroPagamentoSelecionado,
        filtroStatusSelecionado,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  };

  useEffect(() => {
    if (montouComponente.current) {
      setCarregamentoInicial(true);
      if (carregamentoInicial) {
        handleAlterarFiltroLateral();
      }
    } else montouComponente.current = true;
  }, [empresaSelecionada, clienteSelecionado]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroPagamentoSelecionado = (event) => {
    const val = filtroPagamentoSelecionado === event ? "" : event;
    setFiltroPagamentoSelecionado(val);
  };

  const handleClickFiltroStatusSelecionado = (event) => {
    const val = filtroStatusSelecionado === event ? "" : event;
    setFiltroStatusSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  useEffect(() => {
    if (textoPesquisa === "" && carregamentoInicial) {
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            valorOrdenacao,
            filtroSituacaoSelecionado,
            filtroPagamentoSelecionado,
            filtroStatusSelecionado,
            empresaSelecionada,
            clienteSelecionado,
            unidadeSelecionada,
            textoPesquisa
          )
        );
      }, 1000);

      return () => clearTimeout(debounce);
    }
  }, [textoPesquisa]);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        valorOrdenacao,
        filtroSituacaoSelecionado,
        filtroPagamentoSelecionado,
        filtroStatusSelecionado,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada,
        textoPesquisa
      )
    );
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} sm={5}>
          <MaterialInputBusca
            type="number"
            id="textoBusca"
            name="textoBusca"
            label="Buscar pelo número"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoBusca}
          />
        </Grid>
        <Grid sm={2} />
        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenadorUm="Ordenador"
            ordenacaoColuna={listarOrganizarPor()}
            ordenadorSeis="Status"
            filtroStatus={filtroStatusPor()}
            ordemSelecionada={ordemSelecionada}
            ordemColunaSelecionada={ordemColunaSelecionada}
            filtroStatusSelecionados={filtroStatusSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltroStatus={handleClickFiltroStatusSelecionado}
            onClickSortOrder={handleClickSortOrderSelecionado}
            onClickAtivarAgora={handleClickAtivarFiltro}
            larguraBotao="3"
            filtroPagamento={filtroPagamentoPor()}
            filtroPagamentoSelecionados={filtroPagamentoSelecionado}
            onClickFiltroPagamento={handleClickFiltroPagamentoSelecionado}
            ordenadorSete="Pagamentos"
          />
        </Grid>
      </Grid>
      <Grid container className="mt-4">
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar=""
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          itemsPorPagina={20}
          noHeader
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} md={3} lg={3}>
          <Botao
            type="button"
            label="Nova Boleta"
            className={classes.button}
            onClick={() => {
              history.push(`${RotasDTO.BoletasGD}/cadastro`);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ListagemBoletaGD;
