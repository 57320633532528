import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const Label = styled.label`
  padding: 0 8px 0 0;
  margin: 0;
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  color: #000000 !important;
  padding: 0 !important;
`;

export const Select = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08),
      0px 2px 8px 0px rgba(0, 0, 0, 0.12) !important;
    min-width: 252px;
  }

  ul {
    padding: 0 !important;
  }
`;

export const Item = styled(MenuItem)`
  color: #4f4f4f !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 16px !important;
  text-transform: uppercase;
`;
