export default class OperacaoDto {
  constructor(
    unidadeNome,
    id,
    campoId,
    unidadesSelecionadas,
    disabled,
    status,
    campoSelecionar,
    editarUnidade,
    tooltipTitulo
  ) {
    this.unidadeNome = unidadeNome;
    this.id = id;
    this.campoId = campoId;
    this.unidadesSelecionadas = unidadesSelecionadas;
    this.disabled = disabled;
    this.status = status;
    this.campoSelecionar = campoSelecionar;
    this.editarUnidade = editarUnidade;
    this.tooltipTitulo = tooltipTitulo;
  }
}
