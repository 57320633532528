import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listarBoletas } from "../../../../servicos/boletasGDServico";

export default class BoletasHelper {
  static async listarBoletas(filtro, history, rotas) {
    try {
      const resultado = await listarBoletas(filtro);

      return RetornoEndpointDto.Sucesso(
        "Boletas obtidas com sucesso",
        resultado?.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de boletas"
      );
    } finally {
      const rotaAtual = rotas?.rotaAtual?.caminho;
      if (rotaAtual === RotasDTO.BoletasGD)
        history.replace({
          pathname: RotasDTO.BoletasGD,
          state: { dadosBoletaGd: null }
        });
    }
  }
}
