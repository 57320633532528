import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Grid, Checkbox, Box } from "@mui/material";
import { Loader, Notificacao } from "componentes";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import SelectArredondado from "componentes/selectArredondado";
import MaterialInputBusca from "componentes/inputBusca";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Botao from "componentes/botao";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import theme from "themes";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { store } from "global/redux";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import TabelaDto from "./tabelaDto";
import ColunaFiltro from "../ColunaCustomizada/colunaFiltroSelect";
import ColunaCheckbox from "../ColunaCustomizada/colunaCheckbox";
import ColunaData from "../ColunaCustomizada/colunaFiltroData";
import AtualizarStatusMigracaoHelper from "./helper";
import ModalCancelamento from "../../AcompanhamentoCliente/Componentes/ModalCancelamento";
import FiltroUnidades from "../Componentes/FiltroUnidades";
import TabConteudo from "../Componentes/TabConteudo";
import FormularioAtualizarStatus from "../Componentes/FormularioAtualizarStatus";
import { useStyles, StyledTabs, StyledTab } from "./style";

const AtualizarStatusMigracao = () => {
  const classes = useStyles();
  const refTabela = useRef(null);
  const [carregandoOperacoes, setCarregandoOperacoes] = useState();
  const [campo, setCampo] = useState("1");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [dataEnvioMigracao, setDataEnvioMigracao] = useState();
  const [filtroDiasPedidoMigracao, setFiltroDiasPedidoMigracao] = useState();
  const [dataPrevisaoConclusao, setDataPrevisaoConclusao] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState(false);
  const [textoBusca, setTextoBusca] = useState("");
  const [distribuidoraId, setDistribuidoraId] = useState();
  const [distribuidoraLista, setDistribuidoraLista] = useState([]);
  const [labelTextoBusca, setLabelTextoBusca] = useState("");
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState([]);
  const [unidadeIds, setUnidadeIds] = useState([]);
  const [clickedCheckbox, setClickedCheckbox] = useState(false);
  const [dadosTabela, setDadosTabela] = useState();
  const [todosHabilitado, setTodosHabilitado] = useState(false);
  const [modalFiltrarUnidade, setModalFiltrarUnidade] = useState(false);
  const [modalAtualizarStatus, setModalAtualizarStatus] = useState(false);
  const [tab, setTab] = useState(0);
  const [ucsNaoEncontradas, setUcsNaoEncontradas] = useState();
  const [mascaraCpfCnpj, setMascaraCpfCnpj] = useState("###.###.###-##");
  const [notificacao, setNotificacao] = useState();
  const [dadosProcessamento, setDadosProcessamento] = useState();
  const [clicouBuscar, setClicouBuscar] = useState(false);
  const [mensagemUnidade, setMensagemUnidade] = useState("");
  const diasPedidoMigracaoLista = [
    { id: 1, valor: "TODOS" },
    { id: 2, valor: "ATRASADOS" },
    { id: 3, valor: "EM ANDAMENTO" }
  ];

  const handleChangeTab = (event, newValue) => {
    onClickLimpar();
    setTab(newValue);
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        dataEnvioMigracao,
        filtroDiasPedidoMigracao,
        dataPrevisaoConclusao,
        unidadeIds,
        unidadesSelecionadas
      )
    );
  };

  const alterarDataEnvioMigracao = (datas) => {
    setClicouBuscar(true);
    setDataEnvioMigracao(datas);
    handleAlterarFiltroLateral();
  };

  const alterarFiltrodiasPedidoMigracao = (valor) => {
    setClicouBuscar(true);
    setFiltroDiasPedidoMigracao(valor);
    handleAlterarFiltroLateral();
  };

  const alterarDataPrevisaoConclusao = (datas) => {
    setClicouBuscar(true);
    setDataPrevisaoConclusao(datas);
    handleAlterarFiltroLateral();
  };

  const filtrarUnidades = (unidades) => {
    setClicouBuscar(true);
    setUnidadeIds(unidades);
    handleAlterarFiltroLateral();
  };

  const verificarMaiorUc = (unidades) => {
    const uniadesFiltradas = unidades?.filter((item) => item?.quantidade);
    const maiorQuantidade = Math.max(
      ...uniadesFiltradas.map((q) => q?.quantidade)
    );
    const itemMaior = uniadesFiltradas.find(
      (item) => item.quantidade === maiorQuantidade
    );

    return itemMaior;
  };

  const obterDistribuidoras = async () => {
    const lista = await AtualizarStatusMigracaoHelper.listarDistribuidoras(
      setNotificacao
    );

    const listaConcatenada =
      lista?.data?.map((item) => {
        return {
          ...item,
          nomeFantasia: item?.quantidade
            ? `${item?.distribuidora} (${item?.quantidade})`
            : item?.distribuidora,
          desabilitado: !item?.quantidade || item?.quantidade === 0
        };
      }) || [];

    const listaOrdenada = listaConcatenada?.sort((a, b) =>
      a?.quantidade < b?.quantidade ? 1 : b?.quantidade < a?.quantidade ? -1 : 0
    );

    setDistribuidoraLista([
      {
        distribuidoraId: "0",
        nomeFantasia: "TODOS"
      },
      ...listaOrdenada
    ]);
    setDistribuidoraId(verificarMaiorUc(listaOrdenada)?.distribuidoraId || "0");
  };

  const selecionarUnidades = (unidades) => {
    const unidadesFiltradas = unidadesSelecionadas?.filter((id) =>
      unidades?.find((u) => u === id)
    );
    let novasUnidades;
    if (unidadesFiltradas?.length > 0) {
      novasUnidades = unidadesSelecionadas?.filter((id) =>
        unidades?.find((u) => u !== id)
      );
    } else {
      const unidadesSalvas = unidadesSelecionadas || [];
      novasUnidades = [...unidades, ...unidadesSalvas];
    }

    if (
      unidadesSelecionadas?.length > 0 &&
      novasUnidades?.length === dadosTabela?.data?.paginacao?.items?.length
    ) {
      setTodosHabilitado(true);
    } else {
      setTodosHabilitado(false);
    }

    setUnidadesSelecionadas(novasUnidades);
    setClickedCheckbox(true);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        dataEnvioMigracao,
        filtroDiasPedidoMigracao,
        dataPrevisaoConclusao,
        unidadeIds,
        novasUnidades
      )
    );
  };

  const selecionarTodasUnidades = () => {
    let todasSelecionadas = [];
    if (!todosHabilitado && dadosTabela?.data?.paginacao?.items?.length) {
      todasSelecionadas = dadosTabela?.data?.paginacao?.items?.map(
        (item) => item?.operacaoControleId
      );
      setTodosHabilitado(true);
    } else {
      setTodosHabilitado(false);
    }

    setUnidadesSelecionadas(todasSelecionadas);
    setClickedCheckbox(true);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        dataEnvioMigracao,
        filtroDiasPedidoMigracao,
        dataPrevisaoConclusao,
        unidadeIds,
        todasSelecionadas
      )
    );
  };

  const colunas = [
    new ColunaComponentePersonalizado(
      "unidadeNome",
      "Nome da unidade",
      ColunaCheckbox,
      selecionarUnidades
    ),
    new Coluna("unidadeId", "Unidade consumidora"),
    new ColunaData(
      "Data de envio da migração",
      "dataEnvioMigracao",
      dataEnvioMigracao,
      alterarDataEnvioMigracao
    ),
    new ColunaFiltro(
      "Dias desde pedido da migração",
      "diasDesdeInicioMigracao",
      diasPedidoMigracaoLista,
      filtroDiasPedidoMigracao,
      alterarFiltrodiasPedidoMigracao
    ),
    new ColunaData(
      "Data prevista de migração",
      "dataPrevisaoConclusao",
      dataPrevisaoConclusao,
      alterarDataPrevisaoConclusao
    )
  ];

  const retornarStatusTexto = () => {
    setDadosProcessamento();

    if (dadosProcessamento?.status === 1)
      return dadosProcessamento?.quantidade > 1
        ? `Status de ${dadosProcessamento.quantidade} UCs foram alteradas para migrado!`
        : `Status de ${dadosProcessamento.quantidade} UC foi alterada para migrado!`;
    if (dadosProcessamento?.status === 2)
      return dadosProcessamento?.quantidade > 1
        ? `Status de ${dadosProcessamento.quantidade} UCs foram alteradas para reiniciar processo!`
        : `Status de ${dadosProcessamento.quantidade} UC foi alterada para reiniciar processo!`;
    if (dadosProcessamento?.status === 3)
      return dadosProcessamento?.quantidade > 1
        ? `Status de ${dadosProcessamento.quantidade} UCs foram alteradas para atendimento/parceiros`
        : `Status de ${dadosProcessamento.quantidade} UC foi alterada para atendimento/parceiros`;

    return "UCs foram alteradas!";
  };

  const obterOperacoes = async (parametros) => {
    setCarregandoOperacoes(true);
    const parametrosBusca = parametros;

    let parametrosFiltro = "";
    if (dataEnvioMigracao?.dataInicial) {
      parametrosBusca.DataEnvioInicio = moment(
        dataEnvioMigracao.dataInicial
      ).format("YYYY-MM-DD");
    }
    if (dataEnvioMigracao?.dataFinal) {
      parametrosBusca.DataEnvioFim = moment(dataEnvioMigracao.dataFinal).format(
        "YYYY-MM-DD"
      );
    }
    if (filtroDiasPedidoMigracao) {
      parametrosBusca.FiltroDiasPedidoMigracao = filtroDiasPedidoMigracao;
    }
    if (dataPrevisaoConclusao?.dataInicial) {
      parametrosBusca.DataPrevistaInicio = moment(
        dataPrevisaoConclusao.dataInicial
      ).format("YYYY-MM-DD");
    }
    if (dataPrevisaoConclusao?.dataFinal) {
      parametrosBusca.DataPrevistaFim = moment(
        dataPrevisaoConclusao.dataFinal
      ).format("YYYY-MM-DD");
    }
    if (campo && textoBusca) {
      parametrosBusca.CampoBusca = campo === "5" ? "3" : campo;
      parametrosBusca.TextoBusca = textoBusca;
    }
    if (distribuidoraId && distribuidoraId !== "0")
      parametrosBusca.distribuidoraId = distribuidoraId;
    if (unidadeIds.length) {
      unidadeIds.map((unidade) => {
        parametrosFiltro += `&UnidadeIds=${unidade}`;
        return unidade;
      });
    }
    if (tab === 1) {
      parametrosBusca.FiltroStatusMigracao = 5;
    }

    let resultado;

    if (clickedCheckbox) {
      resultado = dadosTabela;
    } else {
      resultado = await AtualizarStatusMigracaoHelper.listarOperacaoTrocaTitularidadePaginado(
        parametrosBusca,
        parametrosFiltro
      );
      setDadosTabela(resultado);
    }

    if (
      campo === "1" &&
      distribuidoraId &&
      textoBusca &&
      resultado.sucesso &&
      resultado?.data?.paginacao?.items?.length === 0
    ) {
      delete parametrosBusca.distribuidoraId;
      const resultadoUnidade = await AtualizarStatusMigracaoHelper.listarOperacaoTrocaTitularidadePaginado(
        parametrosBusca,
        parametrosFiltro
      );
      if (
        resultadoUnidade?.sucesso &&
        resultadoUnidade?.data?.paginacao?.items?.length
      ) {
        setFiltroSelecionado(false);
        setMensagemUnidade(
          <Box>
            <h4>
              A unidade consumidora não está vinculada a esta distribuidora.
            </h4>
            <Botao
              label="Limpar filtro"
              className={classes.botaoFiltrarUc}
              onClick={() => {
                setDistribuidoraId("0");
                setMensagemUnidade("");
                setFiltroSelecionado(true);
                handleAlterarFiltroLateral();
              }}
              selected
            />
          </Box>
        );
      } else
        setMensagemUnidade(
          <Box>
            <h4>
              Esta unidade consumidora não foi encontrada. Por favor, tente
              novamente.
            </h4>
          </Box>
        );
    } else setMensagemUnidade("");

    setClickedCheckbox(false);
    setCarregandoOperacoes(false);

    if (!resultado.sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: resultado?.mensagem || "Ocorreu um erro ao obter distribuidoras!"
      });

      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    let unidadesSelecionar = unidadesSelecionadas;
    const unidadeIdNaoEncontradas = !resultado?.data?.paginacao?.items.length
      ? unidadeIds
      : resultado?.data?.unidadesNaoEncontrados;
    let selecionarTodasUnidades = true;

    if (unidadeIds?.length) {
      if (unidadeIdNaoEncontradas?.length) {
        if (unidadeIdNaoEncontradas?.length > 1) {
          const unidadesFormatadas = unidadeIdNaoEncontradas?.map(
            (unidade, index) => {
              if (index === unidadeIdNaoEncontradas?.length - 1) return unidade;
              return `${unidade}, `;
            }
          );
          setUcsNaoEncontradas(
            <Box>
              As unidades consumidoras (<strong>{unidadesFormatadas}</strong>)
              não foram encontradas. Por favor, verifique e tente novamente.
            </Box>
          );
        } else {
          setUcsNaoEncontradas(
            <Box>
              A unidade consumidora (<strong>{unidadeIdNaoEncontradas}</strong>)
              não foi encontrada. Por favor, verifique e tente novamente.
            </Box>
          );
        }
        selecionarTodasUnidades = false;
        resultado = dadosTabela;
        setDadosTabela(resultado);
      } else {
        setUcsNaoEncontradas();
        setModalFiltrarUnidade(false);
      }

      if (
        resultado?.data?.paginacao?.items?.length &&
        !clickedCheckbox &&
        selecionarTodasUnidades
      ) {
        setTodosHabilitado(true);
        unidadesSelecionar = resultado?.data?.paginacao?.items?.map(
          (unidade) => unidade.operacaoControleId
        );
        setUnidadesSelecionadas(unidadesSelecionar);
      }

      const novasUnidadesIds = unidadeIds.filter((id) =>
        resultado?.data?.paginacao?.items.find(
          (unidade) => unidade?.unidadeId === Number(id)
        )
      );
      setUnidadeIds(novasUnidadesIds);
    }

    if (resultado?.data?.paginacao?.items?.length === 0) {
      setUnidadesSelecionadas([]);
      setTodosHabilitado(false);
      if (tab === 1 && dadosProcessamento) {
        setNotificacao({
          aberto: true,
          tipo: "success",
          texto: retornarStatusTexto()
        });
      }
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = await resultado?.data?.paginacao?.items?.map((res) => {
      return new TabelaDto(
        res?.id,
        res?.unidadeNome,
        res?.unidadeId,
        res?.dataEnvioMigracao,
        res?.dataPrevisaoConclusao,
        res?.diasDesdeInicioMigracao,
        unidadesSelecionar,
        res?.operacaoControleId,
        tab === 1,
        res?.status,
        "operacaoControleId"
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginacao.pageSize,
      resultado.data.paginacao.totalCount
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterOperacoes({
      ItensPorPagina: parametros.totalPagina,
      Pagina: parametros.pagina
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        null,
        null,
        null,
        null,
        null
      )
    );
  };

  const onClickBuscar = () => {
    setClicouBuscar(true);
    handleClickAtivarFiltro();
  };

  const habilitarLimparFiltro = () => {
    if (
      (unidadeIds.length ||
        textoBusca ||
        filtroDiasPedidoMigracao ||
        dataEnvioMigracao ||
        dataPrevisaoConclusao ||
        distribuidoraId !== "0") &&
      clicouBuscar
    )
      setFiltroSelecionado(true);
    else setFiltroSelecionado(false);
  };

  const onClickLimpar = () => {
    setCampo("1");
    setTextoBusca("");
    setDataEnvioMigracao();
    setFiltroDiasPedidoMigracao();
    setDataPrevisaoConclusao();
    setDistribuidoraId("0");
    setUnidadesSelecionadas([]);
    setTodosHabilitado(false);
    setUnidadeIds([]);
    setFiltroSelecionado(false);
    setClicouBuscar(false);
    setMensagemUnidade("");
    setFiltroAdicionalTabela(new FiltroAdicionalTabela());
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  useEffect(() => {
    obterDistribuidoras();
  }, []);

  useEffect(() => {
    habilitarLimparFiltro();
  }, [
    unidadeIds,
    filtroDiasPedidoMigracao,
    dataEnvioMigracao,
    dataPrevisaoConclusao,
    clicouBuscar
  ]);

  useEffect(() => {
    setTextoBusca("");

    if (campo === "3") {
      setLabelTextoBusca("Pesquisar por CPF");
      setMascaraCpfCnpj("###.###.###-##");
    } else if (campo === "5") {
      setLabelTextoBusca("Pesquisar por CNPJ");
      setMascaraCpfCnpj("##.###.###/####-##");
    } else if (campo === "4") {
      setLabelTextoBusca("Pesquisar por número de instalação");
      setMascaraCpfCnpj(null);
    } else if (campo === "2") {
      setLabelTextoBusca("Pesquisar por nome do cliente");
    } else if (campo === "1") {
      setLabelTextoBusca("Pesquisar por nome da unidade");
    }
  }, [campo]);

  return (
    <Loader loading={carregandoOperacoes} className="w-auto">
      <Notificacao fechar={() => setNotificacao({})} mensagem={notificacao} />
      <ModalCancelamento
        titulo={ucsNaoEncontradas ? "UC não encontrada!" : "Filtrar por UC"}
        item={modalFiltrarUnidade}
        conteudo={
          <FiltroUnidades
            textoNaoEncontrado={ucsNaoEncontradas}
            onFiltrar={(ucs) => {
              if (ucsNaoEncontradas) {
                if (ucs?.length === 0) {
                  handleAlterarFiltroLateral();
                }
                setUcsNaoEncontradas();
                return;
              }
              filtrarUnidades(ucs);
            }}
            onCancelar={() => {
              setModalFiltrarUnidade(false);
              setUcsNaoEncontradas();
            }}
            unidades={unidadeIds}
            setUnidades={setUnidadeIds}
            inputType="number"
            carregando={carregandoOperacoes}
          />
        }
        onCancelar={() => {
          setModalFiltrarUnidade(false);
          setUcsNaoEncontradas();
        }}
      />
      <ModalCancelamento
        titulo="Atualizar status"
        item={modalAtualizarStatus}
        conteudo={
          <FormularioAtualizarStatus
            unidadesSelecionadas={unidadesSelecionadas}
            onCancelar={() => setModalAtualizarStatus(false)}
            setCarregando={setCarregandoOperacoes}
            obterDados={(dadosProcessamento) => {
              onClickLimpar();
              setUnidadesSelecionadas();
              setDadosProcessamento(dadosProcessamento);
            }}
            setNotificacao={setNotificacao}
          />
        }
        onCancelar={() => setModalAtualizarStatus(false)}
      />
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <h2 className={classes.titulo}>Atualizar status de migração</h2>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectArredondado
            select
            id="Distribuidora"
            name="Distribuidora"
            label="Distribuidora"
            placeholder="Distribuidora"
            valueKey="distribuidoraId"
            valueName="nomeFantasia"
            className={classes.select}
            dataSource={distribuidoraLista}
            value={distribuidoraId}
            onChange={(e) => setDistribuidoraId(e.target.value)}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectArredondado
            select
            id="campo"
            name="campo"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "NOME DA UNIDADE", valor: "1" },
              { nome: "NOME DO CLIENTE", valor: "2" },
              { nome: "CPF DA UNIDADE", valor: "3" },
              { nome: "CNPJ DA UNIDADE", valor: "5" },
              { nome: "NÚMERO DE INSTALAÇÃO", valor: "4" }
            ]}
            value={campo}
            onChange={(e) => setCampo(e.target.value)}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        {campo === "1" || campo === "2" || campo === "4" ? (
          <Grid item md={3} sm={6} xs={12}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label={labelTextoBusca}
              className={classes.search}
              cleanAdornment
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca}
              onInput={(e) => setTextoBusca(e.target.value)}
              onClickLimpar={() => setTextoBusca("")}
            />
          </Grid>
        ) : (
          <Grid item lg={4} md={4} sm={6} xs={12} className="font-weight-bold">
            <MaterialInputMascara
              type="text"
              id="textoBusca"
              name="textoBusca"
              label={labelTextoBusca}
              mask={mascaraCpfCnpj}
              value={textoBusca}
              renderIconShowHide={false}
              className={classes.search}
              onInputChange={(valor) => setTextoBusca(valor)}
            />
          </Grid>
        )}
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Botao label="Buscar" onClick={() => onClickBuscar()} />
        </Grid>
        <Grid container item xs={12}>
          <Box className={classes.box}>
            <Checkbox
              checked={todosHabilitado}
              onChange={() => selecionarTodasUnidades()}
              sx={{
                color: `${theme.color.secondary} !important`,
                "&.Mui-checked": {
                  color: `${theme.color.secondary} !important`
                }
              }}
              icon={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length ? (
                  <IndeterminateCheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              disabled={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length
              }
            />
          </Box>
          <Box className={classes.box}>
            <Botao
              label="Filtrar por UC"
              className={classes.botaoFiltrarUc}
              onClick={() => setModalFiltrarUnidade(true)}
              selected
              disabled={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length
              }
            />
          </Box>
          {filtroSelecionado && (
            <Box className={classes.box}>
              <Botao
                label="Limpar filtro"
                className={classes.botaoLimpar}
                onClick={() => {
                  onClickLimpar();
                }}
                selected
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {unidadesSelecionadas?.length > 0 && (
        <Grid container className={classes.boxAtualizarStatus} mb={3}>
          <Grid container item xs={3} />
          <Grid
            container
            item
            xs={6}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <span>
              {unidadesSelecionadas?.length}{" "}
              {unidadesSelecionadas?.length > 1
                ? "unidades consumidoras selecionados"
                : "unidade consumidora selecionado"}
            </span>
          </Grid>
          <Grid
            container
            item
            xs={3}
            sx={{ alignItems: "center", justifyContent: "flex-end" }}
          >
            <Botao
              label="Atualizar status"
              className={classes.buttonFiltro}
              onClick={() => setModalAtualizarStatus(true)}
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <StyledTabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="Tab"
            variant="fullWidth"
          >
            <StyledTab
              label="Em aberto"
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <StyledTab
              label="Em processamento"
              id="tab-1"
              aria-controls="tabpanel-1"
            />
          </StyledTabs>
        </Grid>
        <Grid item xs={12}>
          <TabConteudo value={tab} index={0}>
            <TabelaPaginada
              ref={refTabela}
              className={classes.tabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              filtrosAdicionais={filtroAdicionalTabela}
              paginaAtual={1}
              qtdLinhas={3}
              noHeader
              totalItens
              itemsPorPagina={[20, 50, 100]}
              noDataComponent={{
                texto: mensagemUnidade || (
                  <Box>
                    <h4>
                      No momento, não existem status pendentes para esta
                      distribuidora.
                    </h4>
                  </Box>
                ),
                className: classes.naoEncontrado
              }}
            />
            <Box className={classes.naoEncontrado} />
          </TabConteudo>
          <TabConteudo value={tab} index={1}>
            <TabelaPaginada
              ref={refTabela}
              className={classes.tabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              filtrosAdicionais={filtroAdicionalTabela}
              paginaAtual={1}
              qtdLinhas={3}
              noHeader
              totalItens
              itemsPorPagina={[20, 50, 100]}
              noDataComponent={{
                texto: mensagemUnidade || (
                  <Box>
                    <h4>No momento, não existem UCs em processamento.</h4>
                  </Box>
                ),
                className: classes.naoEncontrado
              }}
            />
          </TabConteudo>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default AtualizarStatusMigracao;
