import * as React from "react";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Item, Select, Label } from "./style";

const SelectMenu = ({ opcoes, label, onChange, placement }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (opcao = null) => {
    setAnchorEl(null);
    if (opcao?.id) onChange(opcao);
  };

  return (
    <div>
      <Button
        type="button"
        id="select-button"
        aria-controls={open ? "select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Label>{label}</Label>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Select
        id="select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "select-button"
        }}
        slotProps={{
          popper: {
            placement
          }
        }}
      >
        {opcoes?.map((opcao) => (
          <Item onClick={() => handleClose(opcao)}>{opcao.descricao}</Item>
        ))}
      </Select>
    </div>
  );
};

SelectMenu.propTypes = {
  opcoes: PropTypes.arrayOf(),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  placement: PropTypes.string
};

SelectMenu.defaultProps = {
  opcoes: [],
  label: "",
  onChange: () => {},
  placement: "auto"
};

export default SelectMenu;
