import { makeStyles } from "@mui/styles";
import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    border: "0 none",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "30% !important",
    padding: "5px",
    [theme.breakpoints.down("lg")]: {
      width: "35% !important"
    },
    [theme.breakpoints.down("md")]: {
      width: "60% !important"
    },
    [theme.breakpoints.down("sm")]: {
      width: "98% !important"
    }
  },
  scroll: {
    padding: "15px",
    maxHeight: "100vh",
    overflow: "scroll",
    width: "100%"
  },
  fechar: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    color: `${themes.color.secondaryText} !important`,
    border: "none",
    backgroundColor: "transparent"
  },

  header: {
    "& h3": {
      background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      "-moz-background-clip": "text",
      "-moz-text-fill-color": "transparent",
      fontWeight: "bold",
      fontSize: "24px !important",
      lineHeight: "32px",
      marginBottom: "8px !important",
      fontFamily: themes.fontTahoma.family
    }
  }
}));
