import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import {
  obterUnidadesAssociacaoPaginado,
  obterDistribuidorasAssociacao,
  obterListaAssociacoes,
  obterListaUsinas,
  processarLoteVinculoAssociacaoUsina
} from "servicos/atividadesServico";

export default class FormularioVincularAssociacaoHelper {
  static async listarDistribuidorasAssociacao(setNotificacao) {
    try {
      const resultado = await obterDistribuidorasAssociacao();

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de distribuidoras obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Ocorreu um erro ao obter distribuidoras!"
      });
    }
  }

  static async listarOperacaoTrocaTitularidadePaginado(
    filtro,
    parametrosAdicionais
  ) {
    try {
      const resultado = await obterUnidadesAssociacaoPaginado(
        filtro,
        parametrosAdicionais
      );

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de Operação Controle obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter a lista de Operação Controle!"
      );
    }
  }

  static async listarAssociacoes(distribuidoraId, setNotificacao) {
    try {
      const resultado = await obterListaAssociacoes(distribuidoraId);

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de associações com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Ocorreu um erro ao obter associações!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter associações!"
      );
    }
  }

  static async listarUsinas(associacaoId, setNotificacao) {
    try {
      const resultado = await obterListaUsinas(associacaoId);

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de usinas com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message || "Ocorreu um erro ao obter usinas!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu um erro ao obter usinas!"
      );
    }
  }

  static async vincularAssociacaoUsina(dados, setNotificacao) {
    try {
      const resultado = await processarLoteVinculoAssociacaoUsina(dados);

      return RetornoEndpointDto.Sucesso(
        "Lote vinculado a associação com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: error?.response?.data?.message || "Ocorreu um erro ao vincular!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu um erro ao vincular!"
      );
    }
  }
}
