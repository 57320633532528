import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao, Loader } from "componentes";
import SelectAutoComplete from "componentes/selectAutocomplete";
import BotaoOutLine from "componentes/botaoOutline";
import ListagemHelper from "../../helper";

import { useStyles } from "./style";

const FormularioAtualizarStatus = (props) => {
  const {
    onCancelar,
    setCarregando,
    setNotificacao,
    obterDados,
    carregando,
    naoEncontrado,
    setNaoEcontrado
  } = props;
  const classes = useStyles();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState(false);
  const [indicador, setIndicador] = useState(null);
  const [listaIndicadores, setListaIndicadores] = useState([]);
  const [carregandoIndicadores, setCarregandoIndicadores] = useState(false);
  const [listaIndicados, setListaIndicados] = useState([]);
  const [indicado, setIndicado] = useState(null);
  const [carregandoIndicados, setCarregandoIndicados] = useState(false);
  const [indicadorBusca, setIndicadorBusca] = useState("");
  const [indicadoBusca, setIndicadoBusca] = useState("");
  const [desabilitarLimpar, setDesabilitarLimpar] = useState(false);

  const obterIndicadores = useCallback(async (nome) => {
    setCarregandoIndicadores(true);
    const resultado = await ListagemHelper.obterClientes(
      "NomeFantasia",
      nome,
      setNotificacao
    );

    setCarregandoIndicadores(false);

    if (resultado.sucesso) {
      setIndicador(null);
      setListaIndicadores(resultado?.data?.clientes || []);
      return;
    }

    setNotificacao({
      aberto: true,
      tipo: "error",
      texto: resultado.mensagem
    });
  }, []);

  const obterIndicados = useCallback(async (nome) => {
    setCarregandoIndicados(true);
    const resultado = await ListagemHelper.obterClientes(
      "NomeFantasia",
      nome,
      setNotificacao
    );
    setCarregandoIndicados(false);

    if (resultado.sucesso) {
      setIndicado(null);
      setListaIndicados(resultado?.data?.clientes || []);
      return;
    }

    setNotificacao({
      aberto: true,
      tipo: "error",
      texto: resultado.mensagem
    });
  }, []);

  const validarBotao = () => {
    if (indicado && indicador) {
      setDesabilitarSalvar(false);
      return;
    }

    setDesabilitarSalvar(true);
  };

  const onSalvar = async () => {
    setCarregando(true);
    setDesabilitarSalvar(true);
    setDesabilitarLimpar(true);

    const unidadesIndicador = await ListagemHelper.listarUnidadesPorClienteId(
      indicador?.id
    );
    const unidadesIndicado = await ListagemHelper.listarUnidadesPorClienteId(
      indicado?.id
    );

    if (
      !unidadesIndicador?.data?.unidadeConsumidoraResponseList?.length &&
      !unidadesIndicado?.data?.unidadeConsumidoraResponseList?.length
    ) {
      setNaoEcontrado({
        titulo: "Atenção: UC desativada!",
        texto: "Indicador e Indicado não possuem uma unidade consumidora ativa."
      });
      setDesabilitarSalvar(false);
      setCarregando(false);
      setDesabilitarLimpar(false);
      return;
    }

    if (!unidadesIndicado?.data?.unidadeConsumidoraResponseList?.length) {
      setNaoEcontrado({
        titulo: "Atenção: UC desativada!",
        texto: "Indicado não possui uma unidade consumidora ativa."
      });
      setDesabilitarSalvar(false);
      setCarregando(false);
      setDesabilitarLimpar(false);
      return;
    }

    if (!unidadesIndicador?.data?.unidadeConsumidoraResponseList?.length) {
      setNaoEcontrado({
        titulo: "Atenção: UC desativada!",
        texto: "Indicador não possui uma unidade consumidora ativa."
      });
      setDesabilitarSalvar(false);
      setCarregando(false);
      setDesabilitarLimpar(false);
      return;
    }

    const cuponsIndicado = await ListagemHelper.obterClienteCupom(indicado?.id);

    if (cuponsIndicado?.data.length) {
      setNaoEcontrado({
        titulo: "Indicado já possui cupom!",
        texto:
          "O sistema identificou que o indicado já recebeu um cupom do MGM! A bonificação é concedida apenas uma vez.Verifique as informações e tente novamente."
      });
      setDesabilitarSalvar(false);
      setCarregando(false);
      setDesabilitarLimpar(false);
      return;
    }

    const cuponsIndicador = await ListagemHelper.obterClienteCupom(
      indicador?.id
    );

    const resultadoAtualizar = await ListagemHelper.postarCupom(
      {
        clienteIndicadoId: indicado?.id,
        unidadeIndicadoId:
          unidadesIndicado?.data?.unidadeConsumidoraResponseList[0]?.id,
        nomeIndicado: indicado?.nomeFantasia,
        cupom: cuponsIndicador?.data[0]?.codigo
      },
      setNotificacao
    );

    setCarregando(false);
    setDesabilitarSalvar(false);
    setDesabilitarLimpar(false);

    if (resultadoAtualizar?.data) {
      setNotificacao({
        aberto: true,
        tipo: "success",
        texto: "Desconto criado com sucesso!"
      });

      limparDados();
      obterDados();
      setNaoEcontrado();
    }
  };

  const limparDados = () => {
    setDesabilitarSalvar();
    setIndicador(null);
    setListaIndicadores([]);
    setCarregandoIndicadores(false);
    setListaIndicados([]);
    setIndicado(null);
    setCarregandoIndicados(false);
    setIndicadorBusca();
    setIndicadoBusca();
    onCancelar();
    setDesabilitarLimpar(false);
    setNaoEcontrado();
  };

  useEffect(() => {
    validarBotao();
  }, [indicado, indicador]);

  useEffect(() => {
    if (indicadoBusca?.length > 2) obterIndicados(indicadoBusca);
  }, [indicadoBusca]);

  useEffect(() => {
    if (indicadorBusca?.length > 2) obterIndicadores(indicadorBusca);
  }, [indicadorBusca]);

  return (
    <Loader loading={carregando} className="w-auto">
      <Grid container pt={1}>
        {!naoEncontrado ? (
          <>
            <Grid item xs={12} className="font-weight-bold">
              <label htmlFor="associacao" className={classes.label}>
                Indicador
              </label>
              <Loader loading={carregandoIndicadores}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="indicador"
                  name="indicador"
                  valueKey="id"
                  marginBottom="0"
                  placeholder="Digite o nome do indicador"
                  defaultValue={indicador?.id}
                  value={indicador?.id}
                  options={listaIndicadores?.filter(
                    (indicador) => indicador.nomeFantasia
                  )}
                  getOptionLabel={(option) => option.nomeFantasia}
                  onChange={(e, item) => {
                    setIndicador(item || null);
                    setIndicadorBusca("");
                  }}
                  onChangeSelect={(e) => {
                    if (!e?.target?.value) setListaIndicadores([]);
                    setIndicadorBusca(e.target.value);
                  }}
                  allowClear
                  valueName="nomeFantasia"
                  className={classes.select}
                />
              </Loader>
            </Grid>
            <Grid item xs={12} className="font-weight-bold" pt={2}>
              <label htmlFor="associacao" className={classes.label}>
                Indicado
              </label>
              <Loader loading={carregandoIndicados}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="indicado"
                  name="indicado"
                  valueKey="id"
                  marginBottom="0"
                  placeholder="Digite o nome do indicado"
                  defaultValue={indicado?.id}
                  value={indicado?.id}
                  options={listaIndicados.filter(
                    (indicado) => indicado.nomeFantasia
                  )}
                  getOptionLabel={(option) => option.nomeFantasia}
                  onChange={(e, item) => {
                    setIndicado(item || null);
                    setIndicadoBusca("");
                  }}
                  onChangeSelect={(e) => {
                    if (!e?.target?.value) setListaIndicados([]);
                    setIndicadoBusca(e.target.value);
                  }}
                  allowClear
                  valueName="nomeFantasia"
                  className={classes.select}
                />
              </Loader>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.naoEncontrado}>
            {naoEncontrado?.texto}
          </Grid>
        )}

        <Grid item xs={6} pt={3} pr={1}>
          <BotaoOutLine
            onClick={() => limparDados()}
            label={naoEncontrado ? "OK" : "Cancelar"}
            className={classes.botaoTransparente}
            disabled={desabilitarLimpar}
          />
        </Grid>
        <Grid item xs={6} pt={3}>
          <Botao
            onClick={() => (naoEncontrado ? setNaoEcontrado() : onSalvar())}
            label={naoEncontrado ? "Tentar novamente" : "Adicionar"}
            className={classes.botao}
            disabled={desabilitarSalvar || carregando}
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

FormularioAtualizarStatus.propTypes = {
  onCancelar: PropTypes.func,
  setCarregando: PropTypes.func,
  setNotificacao: PropTypes.func,
  obterDados: PropTypes.func,
  carregando: PropTypes.bool,
  naoEncontrado: PropTypes.string,
  setNaoEcontrado: PropTypes.func
};

FormularioAtualizarStatus.defaultProps = {
  onCancelar: () => {},
  setCarregando: () => {},
  setNotificacao: () => {},
  obterDados: () => {},
  carregando: false,
  naoEncontrado: "",
  setNaoEcontrado: () => {}
};

export default FormularioAtualizarStatus;
