import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Grid, Box } from "@mui/material";
import { Loader, Notificacao } from "componentes";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import SelectArredondado from "componentes/selectArredondado";
import MaterialInputBusca from "componentes/inputBusca";
import Botao from "componentes/botao";
import { store } from "global/redux";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import TabelaDto from "./tabelaDto";
import ListagemHelper from "./helper";
import ModalCancelamento from "../../Operacoes/AcompanhamentoCliente/Componentes/ModalCancelamento";
import FormularioCadastro from "./Componentes/FormularioCadastro";
import { useStyles } from "./style";

const Listagem = () => {
  const classes = useStyles();
  const refTabela = useRef(null);
  const [carregando, setCarregando] = useState();
  const [campo, setCampo] = useState("NomeIndicador");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [textoBusca, setTextoBusca] = useState("");
  const [labelTextoBusca, setLabelTextoBusca] = useState("");
  const [clickedCheckbox, setClickedCheckbox] = useState(false);
  const [dadosTabela, setDadosTabela] = useState();
  const [modalCadastro, setModalCadastro] = useState(false);
  const [notificacao, setNotificacao] = useState();
  const [naoEncontrado, setNaoEcontrado] = useState();
  const [clicouBuscar, setClicouBuscar] = useState(false);

  const colunas = [
    new Coluna("unidadeNome", "Indicador"),
    new Coluna("unidadeId", "Indicado"),
    new Coluna("dataEnvioMigracao", "Data de indicação")
  ];

  const obterListagemCupoms = async (parametros) => {
    setCarregando(true);
    const parametrosBusca = parametros;

    if (campo && textoBusca) {
      parametrosBusca[campo] = textoBusca;
    }

    let resultado;

    if (clickedCheckbox) {
      resultado = dadosTabela;
    } else {
      resultado = await ListagemHelper.listarPaginado(
        parametrosBusca,
        setNotificacao
      );
      setDadosTabela(resultado);
    }

    setClickedCheckbox(false);
    setCarregando(false);

    if (!resultado.sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: resultado?.mensagem || "Ocorreu um erro ao obter distribuidoras!"
      });

      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = await resultado?.data?.items?.map((res) => {
      return new TabelaDto(
        res?.id,
        (
          <Box className={classes.celulaNome}>
            <p>{res?.nomeIndicador}</p>
            <p>UC: {res?.unidadeIndicadaId}</p>
          </Box>
        ),
        (
          <Box className={classes.celulaNome}>
            <p>{res?.nomeIndicado}</p>
            <p>UC: {res?.unidadeIndicadaId}</p>
          </Box>
        ),
        res?.dataAssinaturaContrato
          ? moment(res?.dataAssinaturaContrato)?.format("DD/MM/YYYY")
          : "-"
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.pageSize,
      resultado.data.totalCount
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterListagemCupoms({
      Tamanho: parametros.totalPagina,
      Pagina: parametros.pagina
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(new FiltroAdicionalTabela(textoBusca));
  };

  const onClickBuscar = () => {
    setClicouBuscar(true);
    handleClickAtivarFiltro();
  };

  const onClickLimpar = () => {
    setCampo("NomeIndicador");
    setTextoBusca("");
    setFiltroAdicionalTabela(new FiltroAdicionalTabela());
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  useEffect(() => {
    setTextoBusca("");

    if (campo === "NomeIndicador") {
      setLabelTextoBusca("Pesquisar por nome do indicador");
    } else if (campo === "2") {
      setLabelTextoBusca("Pesquisar por nome do indicado");
    } else if (campo === "3") {
      setLabelTextoBusca("Pesquisar por nome da unidade");
    }
  }, [campo]);

  return (
    <Loader loading={carregando} className="w-auto">
      <Notificacao fechar={() => setNotificacao({})} mensagem={notificacao} />
      <ModalCancelamento
        titulo={naoEncontrado ? naoEncontrado?.titulo : "Adicionar MGM"}
        item={modalCadastro}
        conteudo={
          <FormularioCadastro
            onCancelar={() => setModalCadastro(false)}
            setCarregando={setCarregando}
            obterDados={() => onClickLimpar()}
            setNotificacao={setNotificacao}
            naoEncontrado={naoEncontrado}
            setNaoEcontrado={setNaoEcontrado}
            carregando={carregando}
          />
        }
        onCancelar={() => {
          setModalCadastro(false);
          setNaoEcontrado();
        }}
      />
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <h2 className={classes.titulo}>Listagem</h2>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectArredondado
            select
            id="campo"
            name="campo"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "INDICADOR", valor: "NomeIndicador" },
              { nome: "INDICADO", valor: "NomeIndicado" },
              { nome: "UC", valor: "UnidadeIndicadaId" }
            ]}
            value={campo}
            onChange={(e) => setCampo(e.target.value)}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label={labelTextoBusca}
            className={classes.search}
            cleanAdornment
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca}
            onInput={(e) => setTextoBusca(e.target.value)}
            onClickLimpar={() => setTextoBusca("")}
          />
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Botao label="Buscar" onClick={() => onClickBuscar()} />
        </Grid>
        <Grid container item xs={12}>
          <Box className={classes.box}>
            <Botao
              label="Adicionar MGM"
              className={classes.botaoFiltrarUc}
              onClick={() => setModalCadastro(true)}
              selected
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TabelaPaginada
            ref={refTabela}
            className={classes.tabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            qtdLinhas={3}
            noHeader
            totalItens
            itemsPorPagina={20}
            noDataComponent={{
              texto: (
                <Box>
                  {clicouBuscar && (
                    <h4>
                      Essa pesquisa não foi encontrada. Por favor, tente
                      novamente.
                    </h4>
                  )}
                </Box>
              ),
              className: classes.naoEncontrado
            }}
          />
          <Box className={classes.naoEncontrado} />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default Listagem;
