import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { InputRadioGroup, Loader } from "componentes";
import { useSelector } from "react-redux";
import { buscarPorId as buscarEmpresaPorId } from "servicos/empresaServico";
import { buscarPorId as buscarClientePorId } from "servicos/clientesServico";
import { buscarPorId } from "servicos/unidadesServico";
import UnidadeHelper from "../helper";
import GeracaoDistribuidaForm from "./Componentes/GeracaoDistribuida";
import { useStyles } from "./style";

const UnidadesCadastro = ({ id, setNotificacao, onSalvar }) => {
  const classes = useStyles();
  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  const produtos = UnidadeHelper.obterProdutos();
  const [produtoSelecionado, setProdutoSelecionado] = useState(
    produtos[0].valor
  );
  const [dadosUnidade, setDadosUnidade] = useState(null);

  const onChangeProduto = async (valor) => {
    setProdutoSelecionado(valor);
  };

  const obterUnidade = useCallback(async (idUnidade) => {
    setDadosUnidade(null);
    try {
      setCarregandoUnidade(true);
      const response = await buscarPorId(idUnidade);
      if (response?.status === 200 && response?.data) {
        setDadosUnidade(response?.data);
        setProdutoSelecionado(`${response?.data.produtoId}`);
      }
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Erro interno, entre em contato com o suporte!"
      });
    } finally {
      setCarregandoUnidade(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      obterUnidade(id);
    }
  }, [id, obterUnidade]);

  const [empresaUnidade, setEmpresaUnidade] = useState();
  const [clienteUnidade, setClienteUnidade] = useState();

  // Nome da empresa selecionada
  const nomeEmpresa = useMemo(() => {
    if (empresaUnidade?.nomeFantasia) return empresaUnidade?.nomeFantasia;

    const empresa = usuario?.empresas.find(
      (item) => String(item?.id) === String(usuario?.empresaSelecionada)
    );

    return empresa?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, empresaUnidade]);

  const obterEmpresa = useCallback(async () => {
    if (clienteUnidade?.empresaGrupoDeltaId) {
      try {
        const empresa = await buscarEmpresaPorId(
          clienteUnidade?.empresaGrupoDeltaId
        );

        if (empresa?.data) {
          setEmpresaUnidade(empresa?.data);
        }
      } catch (error) {
        setNotificacao({
          aberto: true,
          tipo: "error",
          texto: "Erro interno, entre em contato com o suporte!"
        });
      }
    }
  }, [clienteUnidade]);

  useEffect(() => {
    obterEmpresa();
  }, [obterEmpresa]);

  const obterCliente = useCallback(async () => {
    if (dadosUnidade?.clienteId) {
      try {
        const cliente = await buscarClientePorId(dadosUnidade?.clienteId);

        if (cliente?.data) {
          setClienteUnidade(cliente?.data);
        }
      } catch (error) {
        setNotificacao({
          aberto: true,
          tipo: "error",
          texto: "Erro interno, entre em contato com o suporte!"
        });
      }
    }
  }, [dadosUnidade]);

  useEffect(() => {
    obterCliente();
  }, [obterCliente]);

  // Nome da cliente selecionado
  const nomeCliente = useMemo(() => {
    let cliente = "";

    if (dadosUnidade?.clienteId) {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(dadosUnidade?.clienteId)
      );

      if (!cliente && clienteUnidade) return clienteUnidade?.nomeFantasia ?? "";
    } else {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(usuario?.clienteSelecionado)
      );
    }

    return cliente?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, clienteUnidade]);

  return (
    <Loader loading={carregandoUnidade}>
      <Grid container className={classes.container}>
        <Grid item xs={10} lg={11} pb={2}>
          <h4>
            Editar unidade consumidora
            {dadosUnidade?.id && nomeEmpresa !== ""
              ? ` na empresa ${nomeEmpresa}`
              : ""}
            {dadosUnidade?.id && nomeCliente !== ""
              ? ` e cliente ${nomeCliente}`
              : ""}
          </h4>
        </Grid>
        <Grid item xs={12} className="font-weight-bold">
          <h4>Produto</h4>
        </Grid>
        <Grid item xs={12} className="font-weight-bold">
          <InputRadioGroup
            type="text"
            id="produtoId"
            name="produtoId"
            renderIconShowHide={false}
            customValue={produtoSelecionado}
            onChange={(valor) => onChangeProduto(valor.target.value)}
            defaultValue={produtoSelecionado}
            className={classes.inputRadio}
            classNameLabel={classes.inputRadioLabel}
            itens={produtos}
            setNotificacao={setNotificacao}
            disabled
          />
        </Grid>
      </Grid>
      <GeracaoDistribuidaForm
        id={id}
        dadosUnidade={dadosUnidade}
        produtoSelecionado={produtoSelecionado}
        nomeEmpresa={nomeEmpresa}
        nomeCliente={nomeCliente}
        clienteUnidade={clienteUnidade}
        rotas={rotas}
        menu={menu}
        onSalvar={onSalvar}
        setCarregandoUnidade={setCarregandoUnidade}
        setNotificacao={setNotificacao}
      />
    </Loader>
  );
};

UnidadesCadastro.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setNotificacao: PropTypes.func,
  onSalvar: PropTypes.func
};

UnidadesCadastro.defaultProps = {
  id: "",
  setNotificacao: () => {},
  onSalvar: () => {}
};

export default UnidadesCadastro;
