import React, { useState } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { useStyles, Item, ItemText } from "./style";

const SubMenu = ({ funcionalidade }) => {
  const classes = useStyles();
  const rotas = useSelector((state) => state.rotas);
  const { menuSelecionadoColecao } = rotas;
  const usuario = useSelector((state) => state.usuario);
  const themeMobile = useTheme();
  const mobile = useMediaQuery(themeMobile.breakpoints.down("sm"));
  const [menuAberto, setMenuAberto] = useState(null);

  const onClickAbrirMenu = (event) => {
    setMenuAberto(event?.currentTarget);
  };

  const onClickFecharMenu = () => {
    setMenuAberto(null);
  };

  const history = useHistory();
  const [segmentosRoutes, setSegmentosRoutes] = useState(null);

  const handleSubMenu = (rotas) => {
    setTimeout(() => {
      history.push(rotas);
      setSegmentosRoutes(false);
    }, 1000);
  };

  function ordenarMenu(x, y) {
    if (x?.ordenacao && y?.ordenacao) return x?.ordenacao - y?.ordenacao;
    return true;
  }

  const validarSubSegmento = (segmento) => {
    return (
      segmento?.funcionalidadeColecao &&
      segmento?.funcionalidadeColecao?.filter(
        (s) => s?.ativo && s?.idTipoFuncionalidade === 4
      )?.length > 0
    );
  };

  return (
    <Grid
      data-component="SubMenu"
      container
      rowSpacing={1}
      columnSpacing={7}
      className={`w-100 mb-0 ${classes.container}, ${classes.backgroundBlack}`}
      justifyContent="flex-start"
    >
      {menuSelecionadoColecao?.length > 0 &&
        funcionalidade?.funcionalidadeColecao?.length > 0 &&
        funcionalidade?.funcionalidadeColecao
          .filter((segmento) => segmento?.ativo)
          .sort(ordenarMenu)
          .map((segmento, s) => (
            <Grid item key={s} className={classes.gridItem}>
              <Box my={{ xs: 2, sm: 0 }}>
                <ItemText
                  id={`subMenu-${s}`}
                  aria-controls={`subMenu-${s}`}
                  aria-haspopup="true"
                  border="none"
                  component="button"
                  sx={{ background: "none !important" }}
                  onClick={(e) => {
                    if (validarSubSegmento(segmento)) {
                      onClickAbrirMenu(e);
                    } else {
                      setSegmentosRoutes(true);
                      handleSubMenu(RotasDTO[segmento?.caminho]);
                    }
                  }}
                  ativa={
                    segmento?.caminho === rotas?.rotaAtual?.caminho ||
                    segmento?.funcionalidadeColecao.find(
                      (subSegmento) =>
                        subSegmento?.caminho === rotas?.rotaAtual?.caminho
                    )?.caminho
                  }
                  disabled={segmentosRoutes}
                >
                  {segmento?.nome}
                </ItemText>

                <Menu
                  id={`subMenu-${s}`}
                  anchorEl={menuAberto}
                  keepMounted
                  elevation={0}
                  open={Boolean(menuAberto?.id === `subMenu-${s}`)}
                  onClose={onClickFecharMenu}
                  MenuListProps={{ className: classes.menu }}
                >
                  {segmento?.funcionalidadeColecao?.length > 0 &&
                    segmento?.funcionalidadeColecao
                      .filter(
                        (subSegmento) =>
                          subSegmento?.ativo &&
                          subSegmento.idFuncionalidadePai === segmento.id &&
                          subSegmento.idTipoFuncionalidade === 4
                      )
                      .sort(ordenarMenu)
                      .map((subSegmento) => (
                        <MenuItem
                          className={classes.menuItem}
                          key={subSegmento?.nome}
                          onClick={() => {
                            setSegmentosRoutes(true);
                            handleSubMenu(RotasDTO[subSegmento?.caminho]);
                            onClickFecharMenu();
                          }}
                        >
                          {subSegmento?.nome}
                        </MenuItem>
                      ))}
                </Menu>

                <Item
                  ativa={
                    segmento?.caminho === rotas?.rotaAtual?.caminho ||
                    segmento?.funcionalidadeColecao.find(
                      (subSegmento) =>
                        subSegmento?.caminho === rotas?.rotaAtual?.caminho
                    )?.caminho
                      ? 1
                      : 0
                  }
                  borda={
                    usuario?.usuario?.role === "Perfil Gráficos Delta" ||
                    usuario?.usuario?.role === "Perfil Gráficos Delta Cliente"
                      ? "2px"
                      : "8px"
                  }
                  mobile={!mobile}
                />
              </Box>
            </Grid>
          ))}
    </Grid>
  );
};

SubMenu.propTypes = {
  funcionalidade: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

SubMenu.defaultProps = {
  funcionalidade: {}
};

export default SubMenu;
